import React, { Component } from "react";
import { Nav, Navbar, NavbarToggler, NavItem, NavLink, Collapse, Container, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Slide, Fade, Zoom } from 'react-reveal';
import { withSizes } from 'react-sizes';


import Footer from '../layout/Footer';
import Helmet from 'react-helmet';
import PhotoGallery from '../pages/PhotoGallery'

import CharlotteMorozSongs from '../../images/charlotte-moroz-songs-reduced.jpg';

import SetGenieDownloadMobileTopWEBP from '../../images/set-genie-download-mobile-top.webp';
import SetGenieDownloadMobileTopJPG from '../../images/set-genie-download-mobile-top.jpg';

import CharlotteMorozCivilWarPigeonsMobileTopWEBP from '../../images/charlotte-moroz-civil-war-pigeons-mobile-top-reduced.webp';
import CharlotteMorozCivilWarPigeonsMobileTopJPG from '../../images/charlotte-moroz-civil-war-pigeons-mobile-top-reduced.jpg';

import MarshallDominguezHome from '../../images/marshall-homepage-reduced-more.jpg';

import JakeWhitesellHome from '../../images/jake-whitesell-home-reduced-more.jpg';

import AndrewMusicServicesEdited from '../../images/andrew-moroz-music-services-edited-reduced.jpg';

import shweebeeRulesPage from '../../images/shweebee-rules-reduced-new-cropped.jpg';

import AbodeWellnessJPG1 from '../../images/abode-wellness.jpg'
import AbodeWellnessJPG2 from '../../images/abode-wellness-2.jpg'
import AbodeWellnessJPG3 from '../../images/abode-wellness-3.jpg'
import AbodeWellnessJPG4 from '../../images/abode-wellness-4.jpg'
import AbodeWellnessJPG5 from '../../images/abode-wellness-5.jpg'
import AbodeWellnessFaqJPG from '../../images/abode-wellness-faq.jpg'
import BlueHouseMushroomJPG from '../../images/blue-house-mushroom.jpg'


class WebDesignPage extends Component {

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.morozDigital = this.morozDigital.bind(this);
        this.listenToScroll = this.listenToScroll.bind(this);

        this.state = {
            navbarIsOpen: false,
            scrollPosition: 0,
            contactButtonOpacity: 0,
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.listenToScroll)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.listenToScroll)
    }

    listenToScroll = () => {
        const winScroll =
            document.body.scrollTop || document.documentElement.scrollTop

        const height =
            document.documentElement.scrollHeight -
            document.documentElement.clientHeight

        const scrolled = winScroll / height

        var opacity = 0;

        if (scrolled < 0.1) {
            opacity = 0;
        } else if ((scrolled >= 0.1) && (scrolled < 0.2)) {
            opacity = (scrolled - 0.1) * 10
        } else {
            opacity = 1
        }

        this.setState({
            scrollPosition: scrolled,
            contactButtonOpacity: opacity
        })

    }

    toggle() {
        this.setState({
            navbarIsOpen: !this.state.navbarIsOpen
        })
    }

    morozDigital() {
        return (
            <span style={{ fontWeight: '900' }}>Moroz&nbsp;Digital</span>
        )
    }

    render() {

        var margin
        if (this.props.width < 992) {
            margin = this.props.width / 300
        } else if (this.props.width < 1601) {
            margin = this.props.width / 440
        } else {
            margin = this.props.width / 440
            // margin = 1000
        }

        var mobilePhotosContainerClassName
        var mobilePhotosSpacerClassName

        const paragraphSpacing = this.props.width < 992 ? 32 : 64
        const sectionSpacing = this.props.width < 992 ? 90 : 180

        if (this.props.width < 992) {
            mobilePhotosSpacerClassName = "col-1"
            mobilePhotosContainerClassName = "col-5"
        } else {
            mobilePhotosSpacerClassName = "col-sm-3"
            mobilePhotosContainerClassName = "col-sm-3"
        }

        var textContainerClassName
        var textSpacerClassName

        var imageContainerClassName
        var imageSpacerClassName

        if (this.props.width < 1450) {
            textSpacerClassName = "col-lg-1"
            textContainerClassName = "col-lg-10"

            imageSpacerClassName = "col-lg-1"
            imageContainerClassName = "col-lg-10"
        } else {
            textSpacerClassName = "col-lg-2"
            textContainerClassName = "col-lg-8"

            imageSpacerClassName = "col-lg-3"
            imageContainerClassName = "col-lg-6"
        }

        const webDesignPhotos = [
            {
                src: BlueHouseMushroomJPG,
                width: 1493,
                height: 3788,
                title: "bluehousemushroom.com: built with Builder.io, React, NodeJS, and Bootstrap"
            },

            {
                src: JakeWhitesellHome,
                width: 1120,
                height: 643,
                title: "jakewhitesell.com: built with React, MongoDB, NodeJS, and Bootstrap"
            },
            // {
            //     src: MarshallDominguezHome,
            //     width: 16,
            //     height: 9,
            //     title: "marshalldominguez.com: built with React, MongoDB, NodeJS, Bootstrap, and PayPal integration"
            // },
            // {
            //     src: AbodeWellnessJPG5,
            //     width: 1,
            //     height: 1.31,
            //     title: "bluehousemushroom.com: built with Builder.io, React, NodeJS, and Bootstrap"
            // },   
            // {
            //     src: AbodeWellnessJPG4,
            //     width: 1,
            //     height: 1.143,
            //     title: "bluehousemushroom.com: built with Builder.io, React, NodeJS, and Bootstrap"
            // }, 
            // {
            //     src: AbodeWellnessJPG3,
            //     width: 1,
            //     height: 1.397,
            //     title: "bluehousemushroom.com: built with Builder.io, React, NodeJS, and Bootstrap"
            // }, 
            // {
            //     src: AbodeWellnessJPG2,
            //     width: 1,
            //     height: 1.489,
            //     title: "bluehousemushroom.com: built with Builder.io, React, NodeJS, and Bootstrap"
            // }, 

            {
                src: CharlotteMorozSongs,
                width: 1494,
                height: 1287,
                title: "charlottemoroz.com: built with HTML5, CSS, JavaScript, PHP, and Bootstrap"
            },
            {
                src: AndrewMusicServicesEdited,
                width: 1493,
                height: 1523,
                title: "andrewmoroz.com: built with React, MongoDB, NodeJS, and Bootstrap"
            },
            {
                src: shweebeeRulesPage,
                width: 1493,
                height: 1023,
                title: "shweebee.com: built with React, MongoDB, NodeJS, and Bootstrap"
            },
            {
                src: AbodeWellnessFaqJPG,
                width: 1493,
                height: 944,
                title: "abodewellnessvt.com: built with Builder.io, React, NodeJS, and Bootstrap"
            },

            // {
            //     src: JakeWhitesellHome,
            //     width: 16,
            //     height: 9,
            //     title: "jakewhitesell.com: built with React, MongoDB, NodeJS, and Bootstrap"
            // },
        ];

        return (

            <div className="p-2">

                {/* nice green */}
                {/* <Helmet bodyAttributes={{ style: 'background-color: rgba(0, 110, 122, 0.8)' }} /> */}

                {/* <Helmet bodyAttributes={{ style: 'background-color: rgba(75, 153, 201, 1)' }} /> */}
                <Helmet bodyAttributes={{ style: "background: linear-gradient(180deg, rgb(3, 60, 79) 0%, rgb(2, 71, 100) 33%, rgb(57, 108, 141) 66%)" }}>
                    <title>Web Design – Moroz Digital</title>
                </Helmet>

                <Button style={{ opacity: this.state.contactButtonOpacity, backgroundColor: "rgb(60, 153, 163)", borderColor: "rgb(60, 153, 163)" }} disabled={this.state.contactButtonOpacity > 0 ? false : true} tag={Link} to="/contact" className="floating-button" color="primary">Tell us about your project!</Button>


                {/* <div className="row">
          <div className="col-lg-12">
            <p className="customTextTwo" style={{ textAlign: "center", fontSize: "35px" }}>
              App Development
            </p>
          </div>
        </div> */}



                {/* <div className="row" style={{ height: paragraphSpacing }}></div> */}
                <div className="row" style={{ height: "32px" }}></div>


                <div className="row">
                    <div className={textSpacerClassName}></div>
                    <div className={textContainerClassName}>

                        <Fade triggerOnce={true}>
                            {/* <p className="customHeader" style={{textAlign: "center"}}>
                We design beautiful, mobile-friendly websites.
              </p> */}

                            {this.props.width > 900 ?
                                <p className="customHeader" style={{ textAlign: "center", fontSize: "30px" }}>
                                    <Zoom left cascade>
                                        <span style={{ display: 'inline-block' }}>We design beautiful, m</span>

                                    </Zoom><Zoom right cascade>
                                        <span style={{ display: 'inline-block' }}>obile-friendly websites.</span>
                                    </Zoom>
                                </p>
                                :
                                this.props.width >= 540 ?
                                    <p className="customHeader" style={{ textAlign: "center" }}>
                                        <Zoom left cascade>
                                            <span style={{ display: 'inline-block' }}>We design beautiful, </span>

                                        </Zoom><Zoom right cascade>
                                            <span style={{ display: 'inline-block' }}>mobile-friendly websites.</span>
                                        </Zoom>
                                    </p>
                                    :

                                    <p className="customHeader" style={{ textAlign: "center", fontSize: "30px" }}>

                                        <Zoom left>
                                            <span style={{ display: 'inline-block' }}>We</span>
                                        </Zoom>

                                        <Zoom left delay={50}>
                                            <span style={{ display: 'inline-block' }}>&nbsp;design</span>
                                        </Zoom>

                                        <Zoom left delay={100}>
                                            <span style={{ display: 'inline-block' }}>&nbsp;beautiful,</span>
                                        </Zoom>

                                        <Zoom left delay={150}>
                                            <span style={{ display: 'inline-block' }}>&nbsp;mobile-</span>
                                        </Zoom>

                                        <Zoom left delay={200}>
                                            <span style={{ display: 'inline-block' }}>&nbsp;friendly</span>
                                        </Zoom>

                                        <Zoom left delay={250}>
                                            <span style={{ display: 'inline-block' }}>&nbsp;websites.</span>
                                        </Zoom>

                                    </p>

                            }
                        </Fade>

                    </div>
                    <div className={textSpacerClassName}></div>
                </div>

                <div className="row" style={{ height: paragraphSpacing }}></div>

                <div className="row">
                    <div className={textSpacerClassName}></div>
                    <div className={textContainerClassName}>

                        <Fade triggerOnce={true}>
                            <p className="customTextTwo">
                                {this.morozDigital()} creates immersive websites and web apps that captivate your audience and draw them in. We emphasize thoughtful, uncluttered layouts, sharp typography, and clean code.
            </p>
                        </Fade>

                    </div>
                    <div className={textSpacerClassName}></div>
                </div>

                <div className="row" style={{ height: "32px" }}></div>

                <div className="row">
                    <div className={textSpacerClassName}></div>
                    <div className={textContainerClassName}>

                        <Fade triggerOnce={true}>
                            <p className="customTextTwo">
                                We work closely with you to bring your creative vision to life, and can accommodate whatever style of collaboration best suits you. Whether you have only a rough idea of your site’s overall shape or a crystal clear concept that you just need published to the web, we can help.
                            </p>
                        </Fade>

                    </div>
                    <div className={textSpacerClassName}></div>
                </div>


                <div className="row" style={{ height: "128px" }}></div>

                <div className="row">
                    <div className="col-lg-2"></div>
                    <div className="col-lg-8">
                        <PhotoGallery photos={webDesignPhotos} columnMode={true} margin={margin} />
                    </div>
                    <div className="col-lg-2"></div>
                </div>


                {/* <div className="row" style={{ height: sectionSpacing }}></div> */}

                <div className="row" style={{ height: (sectionSpacing / 2) }}></div>
                <hr />
                <div className="row" style={{ height: (sectionSpacing / 2) }}></div>

                <Fade triggerOnce={true}>
                    <Slide triggerOnce={true} left duration={750}>

                        <div className="row">
                            <div className={textSpacerClassName}></div>
                            <div className={textContainerClassName}>

                                {this.props.width > 900 ?
                                    <p className="customHeader" style={{ textAlign: "center" }}>
                                        <Zoom left cascade>
                                            <span style={{ display: 'inline-block' }}>Our sites are responsiv</span>

                                        </Zoom><Zoom right cascade>
                                            <span style={{ display: 'inline-block' }}>e from the ground up.</span>
                                        </Zoom>
                                    </p>
                                    :
                                    this.props.width >= 540 ?
                                        <p className="customHeader" style={{ textAlign: "center" }}>
                                            <Zoom left cascade>
                                                <span style={{ display: 'inline-block' }}>Our sites are responsive </span>

                                            </Zoom><Zoom right cascade>
                                                <span style={{ display: 'inline-block' }}>from the ground up.</span>
                                            </Zoom>
                                        </p>
                                        :

                                        <p className="customHeader" style={{ textAlign: "center", fontSize: "30px" }}>

                                            <Zoom left>
                                                <span style={{ display: 'inline-block' }}>Our</span>
                                            </Zoom>

                                            <Zoom left delay={50}>
                                                <span style={{ display: 'inline-block' }}>&nbsp;sites</span>
                                            </Zoom>

                                            <Zoom left delay={100}>
                                                <span style={{ display: 'inline-block' }}>&nbsp;are</span>
                                            </Zoom>

                                            <Zoom left delay={150}>
                                                <span style={{ display: 'inline-block' }}>&nbsp;responsive</span>
                                            </Zoom>

                                            <Zoom left delay={200}>
                                                <span style={{ display: 'inline-block' }}>&nbsp;from</span>
                                            </Zoom>

                                            <Zoom left delay={250}>
                                                <span style={{ display: 'inline-block' }}>&nbsp;the</span>
                                            </Zoom>

                                            <Zoom left delay={300}>
                                                <span style={{ display: 'inline-block' }}>&nbsp;ground</span>
                                            </Zoom>

                                            <Zoom left delay={350}>
                                                <span style={{ display: 'inline-block' }}>&nbsp;up.</span>
                                            </Zoom>

                                        </p>

                                }

                            </div>
                            <div className={textSpacerClassName}></div>
                        </div>
                    </Slide>
                </Fade>

                <div style={{ height: paragraphSpacing }}></div>

                <Fade triggerOnce={true}>
                    <div className="row">
                        <div className={textSpacerClassName}></div>
                        <div className={textContainerClassName}>

                            <p className="customTextTwo">
                                Because the number of screen sizes and browsers a modern website will be viewed on is staggering, we design with flexibility in mind from the get go.
                            </p>

                        </div>
                        <div className={textSpacerClassName}></div>
                    </div>
                </Fade>

                <div className="row" style={{ height: paragraphSpacing }}></div>

                <div className="row">
                    <div className={mobilePhotosSpacerClassName}></div>

                    <div className={mobilePhotosContainerClassName} style={{ paddingBottom: "64px" }}>
                        <Fade triggerOnce={true}>
                            <Slide triggerOnce={true} left duration={450}>
                                {/* <img className="img-fluid" style={{width: "100%"}} src={CharlotteMorozCivilWarPigeonsMobileTop} /> */}

                                <picture className="img-fluid" style={{ width: "100%" }}>
                                    <source type="image/webp" srcset={CharlotteMorozCivilWarPigeonsMobileTopWEBP} />
                                    {/* <source type="image/jp2" srcset={AppStoreIconEdited2JP2} /> */}
                                    <img src={CharlotteMorozCivilWarPigeonsMobileTopJPG} className="img-fluid" style={{ width: "100%" }} />
                                </picture>

                            </Slide>
                        </Fade>
                    </div>

                    <div className={mobilePhotosContainerClassName}>
                        <Fade triggerOnce={true}>
                            <Slide triggerOnce={true} right duration={450}>
                                {/* <img className="img-fluid" src={SetGenieDownloadMobileTop} /> */}

                                <picture className="img-fluid">
                                    <source type="image/webp" srcset={SetGenieDownloadMobileTopWEBP} />
                                    {/* <source type="image/jp2" srcset={AppStoreIconEdited2JP2} /> */}
                                    <img src={SetGenieDownloadMobileTopJPG} className="img-fluid" />
                                </picture>

                            </Slide>
                        </Fade>
                    </div>

                    <div className={mobilePhotosSpacerClassName}></div>
                </div>

                {/* <div className="row" style={{ height: paragraphSpacing }}></div> */}
                {/* <div className="row" style={{ height: sectionSpacing }}></div> */}

                <div className="row" style={{ height: (sectionSpacing / 2) }}></div>
                <hr />
                <div className="row" style={{ height: (sectionSpacing / 2) }}></div>

                {/* NEW */}

                <div className="row">
                    <div className={textSpacerClassName}></div>
                    <div className={textContainerClassName}>

                        <Fade triggerOnce={true}>

                            {this.props.width > 900 ?
                                <p className="customHeader" style={{ textAlign: "center", fontSize: "30px" }}>
                                    <Zoom left cascade>
                                        <span style={{ display: 'inline-block' }}>No templates. We build</span>

                                    </Zoom>
                                    <Zoom right cascade>
                                        <span style={{ display: 'inline-block' }}> everything ourselves.</span>
                                    </Zoom>
                                </p>
                                :
                                this.props.width >= 540 ?
                                    <p className="customHeader" style={{ textAlign: "center" }}>
                                        <Zoom left cascade>
                                            <span style={{ display: 'inline-block' }}>No templates. We build </span>

                                        </Zoom>
                                        <Zoom right cascade>
                                            <span style={{ display: 'inline-block' }}>everything ourselves.</span>
                                        </Zoom>
                                    </p>
                                    :

                                    <p className="customHeader" style={{ textAlign: "center", fontSize: "30px" }}>

                                        <Zoom left>
                                            <span style={{ display: 'inline-block' }}>No</span>
                                        </Zoom>

                                        <Zoom left delay={50}>
                                            <span style={{ display: 'inline-block' }}>&nbsp;templates.</span>
                                        </Zoom>

                                        <Zoom left delay={100}>
                                            <span style={{ display: 'inline-block' }}>&nbsp;We</span>
                                        </Zoom>

                                        <Zoom left delay={150}>
                                            <span style={{ display: 'inline-block' }}>&nbsp;build</span>
                                        </Zoom>

                                        <Zoom left delay={200}>
                                            <span style={{ display: 'inline-block' }}>&nbsp;everything</span>
                                        </Zoom>

                                        <Zoom left delay={250}>
                                            <span style={{ display: 'inline-block' }}>&nbsp;ourselves.</span>
                                        </Zoom>

                                    </p>

                            }
                        </Fade>

                    </div>
                    <div className={textSpacerClassName}></div>
                </div>

                <div className="row" style={{ height: paragraphSpacing }}></div>

                <div className="row">
                    <div className={textSpacerClassName}></div>
                    <div className={textContainerClassName}>

                        <Fade triggerOnce={true}>
                            <p className="customTextTwo">
                                Your business is unique, and it deserves an equally singular web presence. Each of our sites is coded by hand, crafted to the client's specifications. While we are happy to use templates, themes, or other third-party design elements at your request, our default approach is complete customization.
                            </p>
                        </Fade>

                    </div>
                    <div className={textSpacerClassName}></div>
                </div>

                {/* <div className="row" style={{ height: paragraphSpacing }}></div> */}
                {/* <div className="row" style={{ height: sectionSpacing }}></div> */}

                <div className="row" style={{ height: (sectionSpacing / 2) }}></div>
                <hr />
                <div className="row" style={{ height: (sectionSpacing / 2) }}></div>

                <div className="row">
                    <div className={textSpacerClassName}></div>
                    <div className={textContainerClassName}>

                        <Fade triggerOnce={true}>

                            {this.props.width > 900 ?
                                <p className="customHeader" style={{ textAlign: "center", fontSize: "30px" }}>
                                    <Zoom left cascade>
                                        <span style={{ display: 'inline-block' }}>Your success </span>

                                    </Zoom>
                                    <Zoom right cascade>
                                        <span style={{ display: 'inline-block' }}>is our success.</span>
                                    </Zoom>
                                </p>
                                :
                                this.props.width >= 540 ?
                                    <p className="customHeader" style={{ textAlign: "center" }}>
                                        <Zoom left cascade>
                                            <span style={{ display: 'inline-block' }}>Your success </span>

                                        </Zoom>
                                        <Zoom right cascade>
                                            <span style={{ display: 'inline-block' }}>is our success.</span>
                                        </Zoom>
                                    </p>
                                    :

                                    <p className="customHeader" style={{ textAlign: "center", fontSize: "30px" }}>

                                        <Zoom left>
                                            <span style={{ display: 'inline-block' }}>Your</span>
                                        </Zoom>

                                        <Zoom left delay={50}>
                                            <span style={{ display: 'inline-block' }}>&nbsp;success</span>
                                        </Zoom>

                                        <Zoom left delay={100}>
                                            <span style={{ display: 'inline-block' }}>&nbsp;is</span>
                                        </Zoom>

                                        <Zoom left delay={150}>
                                            <span style={{ display: 'inline-block' }}>&nbsp;our</span>
                                        </Zoom>

                                        <Zoom left delay={200}>
                                            <span style={{ display: 'inline-block' }}>&nbsp;success.</span>
                                        </Zoom>

                                    </p>

                            }
                        </Fade>

                    </div>
                    <div className={textSpacerClassName}></div>
                </div>

                <div className="row" style={{ height: paragraphSpacing }}></div>

                <div className="row">
                    <div className={textSpacerClassName}></div>
                    <div className={textContainerClassName}>

                        <Fade triggerOnce={true}>
                            <p className="customTextTwo">
                                As a foundation and guiding principle of our work together, we strive for a thorough understanding of your brand, business model, and position in the market. We’ll ask about your end users and overall audience, your competitors, and your short and long-term goals. Throughout the various stages of design, development, and launch, we keep the focus on strengthening your brand and growing your business.
                            </p>
                        </Fade>

                    </div>
                    <div className={textSpacerClassName}></div>
                </div>

                {/* END NEW */}

                <div className="row" style={{ height: sectionSpacing }}></div>

                <Footer />
            </div >
        );
    }
}

// export default WebDesignPage;

const mapSizesToProps = ({ width }) => ({
    collapseButtonIsShowing: width < 992,
    width: width
})

// export default AboutPage;
export default withSizes(mapSizesToProps)(WebDesignPage)
