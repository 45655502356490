import React, { Component } from "react";
import Helmet from 'react-helmet';
import withSizes from 'react-sizes';
import { Link } from 'react-router-dom';

import Footer from '../layout/Footer';

import AndrewWEBP from '../../images/andrew-reduced.webp';
import AndrewJPG from '../../images/andrew-reduced.jpg';



class AboutPage extends Component {

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);

        this.state = {
            navbarIsOpen: false
        }
    }

    toggle() {
        this.setState({
            navbarIsOpen: !this.state.navbarIsOpen
        })
    }

    render() {
        // console.log("value of this.props.width: " + this.props.width)
        return (

            <div className="p-2">
                {/* <Helmet bodyAttributes={{ style: 'background-color: rgba(75, 153, 201, 1)' }} /> */}
                <Helmet bodyAttributes={{ style: "background-color: rgb(3, 60, 79)" }}>
                    <title>About – Moroz Digital</title>
                </Helmet>


                {/* <div className="fixed-ratio fixed-ratio-16by9">
                    <img className="img-fluid fixed-ratio-content" src={MacBookSetGenieAndIphone8Availabird} />
                </div> */}

                <div style={{ height: "32px" }}></div>


                <div className="row">
                    <div className="col-md-2"></div>

                    {this.props.width < 1680 ?
                        <>

                            <div className="col-md-8">
                                {this.props.width < 1175 ?
                                    <div style={{ textAlign: "center"}}>
                                        {/* <img className="img-fluid" style={{ width: "55vw", marginBottom: "16px" }} src={Andrew} /> */}

                                        <picture className="img-fluid" style={{ width: "55vw", marginBottom: "16px" }}>
                                            <source type="image/webp" srcset={AndrewWEBP} />
                                            {/* <source type="image/jp2" srcset={AppStoreIconEdited2JP2} /> */}
                                            <img src={AndrewJPG} className="img-fluid" style={{ width: "55vw", marginBottom: "16px" }} />
                                        </picture>
                                    </div>
                                    :
                                    // <img className="img-fluid float-left" style={{ width: "31.35vw", marginRight: "30px", marginBottom: "16px" }} src={Andrew} />
                                    <picture className="img-fluid float-left" style={{ width: "31.35vw", marginRight: "30px", marginBottom: "0px" }}>
                                        <source type="image/webp" srcset={AndrewWEBP} />
                                        {/* <source type="image/jp2" srcset={AppStoreIconEdited2JP2} /> */}
                                        <img src={AndrewJPG} className="img-fluid float-left" style={{ width: "31.35vw", marginRight: "30px", marginBottom: "16px" }} />
                                    </picture>
                                }

                                <p className="customTextTwo">
                                    Andrew Moroz started coding in BASIC when he was 10 on an 8086 with no hard drive and 512Kb of RAM. After a string of wild successes, including a pig latin translator and a game called "Truck Racer", he took a long detour into music while continuing to program for fun on the side. At some point, Andrew started seeing apps that didn't exist yet but really needed to, and got back to work. Since then, he has released two iOS/macOS apps, built numerous websites, and generally pursued the expansion of his skill set with an obsessive bent. As a musician, Andrew has performed alongside luminaries like Trey Anastasio, Carlos Santana, and Ani DiFranco; his music site can be found at <a href="https://www.andrewmoroz.com" className="textLink" style={{ color: "rgba(225, 226, 221, 1.0)", fontWeight: "500" }}>andrewmoroz.com</a>. Andrew lives in Burlington, Vermont with his wife Lisa and children Robin and Madeleine.
                            </p>
                            </div>


                        </>
                        :
                        <>

                            <div className="col-md-4">
                                <div className="fixed-ratio fixed-ratio-3by4">
                                    {/* <img className="img-fluid fixed-ratio-content" style={{ paddingBottom: "32px" }} src={Andrew} /> */}

                                    <picture className="img-fluid fixed-ratio-content" style={{ paddingBottom: "32px" }}>
                                        <source type="image/webp" srcset={AndrewWEBP} />
                                        {/* <source type="image/jp2" srcset={AppStoreIconEdited2JP2} /> */}
                                        <img src={AndrewJPG} className="img-fluid fixed-ratio-content" style={{ paddingBottom: "32px" }} />
                                    </picture>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <p className="customTextTwo">
                                    Andrew Moroz started coding in BASIC when he was 10 on an 8086 with no hard drive and 512Kb of RAM. After a string of wild successes, including a pig latin translator and a game called "Truck Racer", he took a long detour into music while continuing to program for fun on the side. At some point, Andrew started seeing apps that didn't exist yet but really needed to, and got back to work. Since then, he has released two iOS/macOS apps, built numerous websites, and generally pursued the expansion of his skill set with an obsessive bent. As a musician, Andrew has performed alongside luminaries like Trey Anastasio, Carlos Santana, and Ani DiFranco; his music site can be found at <a href="https://www.andrewmoroz.com" className="textLink" style={{ color: "rgba(225, 226, 221, 1.0)", fontWeight: "500" }}>andrewmoroz.com</a>. Andrew lives in Burlington, Vermont with his wife Lisa and children Robin and Madeleine.
                                </p>
                            </div>

                        </>
                    }





                    <div className="col-md-2"></div>
                </div>

                <div style={{ height: "64px" }}></div>

                <Footer />
            </div>
        );
    }
}


const mapSizesToProps = ({ width }) => ({
    collapseButtonIsShowing: width < 992,
    width: width
})

// export default AboutPage;
export default withSizes(mapSizesToProps)(AboutPage)
