import React, { Component } from "react";
import { Nav, Navbar, NavbarToggler, NavItem, NavLink, Collapse, Container, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Slide, Fade, Zoom } from 'react-reveal';
import { withSizes } from 'react-sizes';


import Footer from '../layout/Footer';
import Helmet from 'react-helmet';
import PhotoGallery from '../pages/PhotoGallery';



import iPhoneXOutdoorBenchGeneratingSetlistDarkResizedWEBP from '../../images/iphone-x-outdoor-bench-generating-setlist-dark-resized.webp';
import iPhoneXOutdoorBenchGeneratingSetlistDarkResizedJP2 from '../../images/iphone-x-outdoor-bench-generating-setlist-dark-resized.jp2';
import iPhoneXOutdoorBenchGeneratingSetlistDarkResizedJPG from '../../images/iphone-x-outdoor-bench-generating-setlist-dark-resized.jpg';



import MacBookSetGenieAndIphone8AvailabirdWEBP from '../../images/macbook-set-genie-and-iphone-8-availabird-reduced.webp'
import MacBookSetGenieAndIphone8AvailabirdJP2 from '../../images/macbook-set-genie-and-iphone-8-availabird-reduced.jp2'
import MacBookSetGenieAndIphone8AvailabirdPNG from '../../images/macbook-set-genie-and-iphone-8-availabird-reduced.png'

import UniversalAppMontageWEBP from '../../images/universal-app-montage.webp';
import UniversalAppMontageJP2 from '../../images/universal-app-montage.jp2';
import UniversalAppMontagePNG from '../../images/universal-app-montage.png';

import AppStoreConnectGraphsWEBP from '../../images/app-store-connect-graphs-reduced.webp';
import AppStoreConnectGraphsJP2 from '../../images/app-store-connect-graphs-reduced.jp2';
import AppStoreConnectGraphsPNG from '../../images/app-store-connect-graphs-reduced.png';

import AppStoreIconEdited2WEBP from '../../images/app-store-icon-edited-2-reduced.webp';
import AppStoreIconEdited2JP2 from '../../images/app-store-icon-edited-2-reduced.jp2';
import AppStoreIconEdited2PNG from '../../images/app-store-icon-edited-2-reduced.png';

import iPadProSetGenieAppStoreWEBP from '../../images/ipad-pro-set-genie-app-store-reduced.webp';
import iPadProSetGenieAppStoreJP2 from '../../images/ipad-pro-set-genie-app-store-reduced.jp2';
import iPadProSetGenieAppStorePNG from '../../images/ipad-pro-set-genie-app-store-reduced.png';


class AppDevelopmentPage extends Component {

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.morozDigital = this.morozDigital.bind(this);
        this.listenToScroll = this.listenToScroll.bind(this);

        this.state = {
            navbarIsOpen: false,
            scrollPosition: 0,
            contactButtonOpacity: 0,
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.listenToScroll)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.listenToScroll)
    }

    listenToScroll = () => {
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop

        const height = document.documentElement.scrollHeight - document.documentElement.clientHeight

        const scrolled = winScroll / height

        var opacity = 0;

        if (scrolled < 0.1) {
            opacity = 0;
        } else if ((scrolled >= 0.1) && (scrolled < 0.2)) {
            opacity = (scrolled - 0.1) * 10
        } else {
            opacity = 1
        }

        this.setState({
            scrollPosition: scrolled,
            contactButtonOpacity: opacity
        })

    }

    toggle() {
        this.setState({
            navbarIsOpen: !this.state.navbarIsOpen
        })
    }

    morozDigital() {
        return (
            <span style={{ fontWeight: '900' }}>Moroz&nbsp;Digital</span>
        )
    }



    render() {

        var textContainerClassName
        var textSpacerClassName

        var imageContainerClassName
        var imageSpacerClassName

        const paragraphSpacing = this.props.width < 992 ? 32 : 64
        const sectionSpacing = this.props.width < 992 ? 90 : 180

        if (this.props.width < 1450) {
            textSpacerClassName = "col-lg-1"
            textContainerClassName = "col-lg-10"

            imageSpacerClassName = ""
            imageContainerClassName = "col-lg-12"
        } else {
            textSpacerClassName = "col-lg-2"
            textContainerClassName = "col-lg-8"

            imageSpacerClassName = "col-lg-2"
            imageContainerClassName = "col-lg-8"
        }

        return (

            <div className="p-2" style={{ overflow: "hidden" }}>

                <Helmet bodyAttributes={{ style: "background: linear-gradient(180deg, rgb(3, 60, 79) 0%, rgb(2, 71, 100) 33%, rgb(57, 108, 141) 66%)" }}>
                    <title>App Development – Moroz Digital</title>
                </Helmet>

                <Button style={{ opacity: this.state.contactButtonOpacity, backgroundColor: "rgb(60, 153, 163)", borderColor: "rgb(60, 153, 163)" }} disabled={this.state.contactButtonOpacity > 0 ? false : true} tag={Link} to="/contact" className="floating-button" color="primary">Tell us about your project!</Button>


                {/* <div className="row" style={{ height: paragraphSpacing }}></div> */}
                <div className="row" style={{ height: "32px" }}></div>

                <Fade >
                    <div className="row">
                        <div className={textSpacerClassName}></div>
                        <div className={textContainerClassName}>

                            {/* <p className="customHeader" style={{ textAlign: "center" }}>
                We create innovative, powerful iOS and macOS apps.
              </p> */}

                            {this.props.width > 1060 ?
                                <p className="customHeader" style={{ textAlign: "center" }}>
                                    <Zoom left cascade>
                                        <span style={{ display: 'inline-block' }}>We create innovative, pow</span>

                                    </Zoom><Zoom right cascade>
                                        <span style={{ display: 'inline-block' }}>erful iOS and macOS apps.</span>
                                    </Zoom>
                                </p>
                                :
                                this.props.width >= 540 ?
                                    <p className="customHeader" style={{ textAlign: "center" }}>
                                        <Zoom left cascade>
                                            <span style={{ display: 'inline-block' }}>We create innovative, powerful</span>

                                        </Zoom><Zoom right cascade>
                                            <span style={{ display: 'inline-block' }}> iOS and macOS apps.</span>
                                        </Zoom>
                                    </p>
                                    :

                                    <p className="customHeader" style={{ textAlign: "center", fontSize: "30px" }}>

                                        <Zoom left>
                                            <span style={{ display: 'inline-block' }}>We</span>
                                        </Zoom>

                                        <Zoom left delay={50}>
                                            <span style={{ display: 'inline-block' }}>&nbsp;create</span>
                                        </Zoom>

                                        <Zoom left delay={100}>
                                            <span style={{ display: 'inline-block' }}>&nbsp;innovative,</span>
                                        </Zoom>

                                        <Zoom left delay={150}>
                                            <span style={{ display: 'inline-block' }}>&nbsp;powerful</span>
                                        </Zoom>

                                        <Zoom left delay={200}>
                                            <span style={{ display: 'inline-block' }}>&nbsp;iOS</span>
                                        </Zoom>

                                        <Zoom left delay={250}>
                                            <span style={{ display: 'inline-block' }}>&nbsp;and</span>
                                        </Zoom>

                                        <Zoom left delay={300}>
                                            <span style={{ display: 'inline-block' }}>&nbsp;macOS</span>
                                        </Zoom>

                                        <Zoom left delay={350}>
                                            <span style={{ display: 'inline-block' }}>&nbsp;apps.</span>
                                        </Zoom>

                                    </p>

                            }

                        </div>
                        <div className={textSpacerClassName}></div>
                    </div>
                </Fade>

                <div className="row" style={{ height: paragraphSpacing }}></div>

                <div className="row">
                    <div className="col-lg-1"></div>

                    <div className="col-lg-5" style={this.props.width < 992 ? { marginBottom: "32px" } : {}}>

                        <Slide left duration={650}>
                            <div className="fixed-ratio fixed-ratio-16by9">
                                {/* <img className="img-fluid fixed-ratio-content" src={MacBookSetGenieAndIphone8AvailabirdPNG} /> */}

                                <picture className="img-fluid fixed-ratio-content">
                                    <source type="image/webp" srcset={MacBookSetGenieAndIphone8AvailabirdWEBP} />
                                    <source type="image/jp2" srcset={MacBookSetGenieAndIphone8AvailabirdJP2} />
                                    <img src={MacBookSetGenieAndIphone8AvailabirdPNG} className="img-fluid fixed-ratio-content" />
                                </picture>
                            </div>
                        </Slide>

                    </div>


                    <div className="col-lg-5">
                        <Fade >
                            <Slide right duration={650}>
                                <p className="customTextTwo">
                                    {this.morozDigital()} builds state-of-the-art, compelling applications that are beautiful and easy to use. We strive for a perfect balance of form and function: responsive, intuitive interfaces that look great on all device sizes, rock-solid functionality, and comprehensive support.
                                </p>
                            </Slide>
                        </Fade>
                    </div>

                    <div className="col-lg-1"></div>
                </div>


                <div className="row" style={{ height: (sectionSpacing / 2) }}></div>
                <hr />
                <div className="row" style={{ height: (sectionSpacing / 2) }}></div>


                <div className="row">
                    <div className={textSpacerClassName}></div>
                    <div className={textContainerClassName}>

                        <Fade >
                            {/* <Slide  right duration={850} fraction={1.0}> */}

                            {/* <p className="customHeader" style={{ textAlign: "center" }}>
                  User experience is (almost) everything.
                </p> */}


                            {this.props.width > 675 ?
                                <p className="customHeader" style={{ textAlign: "center" }}>
                                    <Zoom left cascade>
                                        <span style={{ display: 'inline-block' }}>User experienc</span>

                                    </Zoom><Zoom right cascade>
                                        <span style={{ display: 'inline-block' }}>e is paramount.</span>
                                    </Zoom>
                                </p>
                                :
                                this.props.width >= 540 ?
                                    <p className="customHeader" style={{ textAlign: "center" }}>
                                        <Zoom left cascade>
                                            <span style={{ display: 'inline-block' }}>User experienc</span>

                                        </Zoom><Zoom right cascade>
                                            <span style={{ display: 'inline-block' }}>e is paramount.</span>
                                        </Zoom>
                                    </p>
                                    :
                                    <p className="customHeader" style={{ textAlign: "center", fontSize: "30px" }}>

                                        <Zoom left>
                                            <span style={{ display: 'inline-block' }}>User</span>
                                        </Zoom>

                                        <Zoom left delay={50}>
                                            <span style={{ display: 'inline-block' }}>&nbsp;experience</span>
                                        </Zoom>

                                        <Zoom left delay={100}>
                                            <span style={{ display: 'inline-block' }}>&nbsp;is</span>
                                        </Zoom>

                                        <Zoom left delay={200}>
                                            <span style={{ display: 'inline-block' }}>&nbsp;paramount.</span>
                                        </Zoom>

                                    </p>

                            }

                            {/* </Slide> */}
                        </Fade>

                    </div>
                    <div className={textSpacerClassName}></div>
                </div>


                <div className="row" style={{ height: paragraphSpacing }}></div>


                <div className="row">
                    <div className="col-lg-1"></div>

                    <div className="col-lg-5" style={this.props.width < 992 ? { marginBottom: "32px" } : {}}>
                        <Slide left duration={750}>
                            <p className="customTextTwo">
                                We believe one of the best metrics of an app’s success is the joy it sparks in its users. Whether it’s central to your business model or one among a suite of offerings, your app is a chance to make people happy while forging a connection with them. To achieve this, at {this.morozDigital()}, we focus on the details – the colors, shapes, symbols, typefaces, animations, and overall flow your customers will experience when they use your app.
            </p>
                        </Slide>
                    </div>

                    <div className="col-lg-5">
                        <Slide right duration={650}>
                            {/* <img className="img-fluid" src={iPhoneXOutdoorBenchGeneratingSetlistDarkResized} /> */}

                            <picture className="img-fluid">
                                <source type="image/webp" srcset={iPhoneXOutdoorBenchGeneratingSetlistDarkResizedWEBP} />
                                <source type="image/jp2" srcset={iPhoneXOutdoorBenchGeneratingSetlistDarkResizedJP2} />
                                <img src={iPhoneXOutdoorBenchGeneratingSetlistDarkResizedJPG} className="img-fluid" />
                            </picture>
                        </Slide>
                    </div>

                    <div className="col-lg-1"></div>
                </div>


                <div className="row" style={{ height: (sectionSpacing / 2) }}></div>
                <hr />
                <div className="row" style={{ height: (sectionSpacing / 2) }}></div>


                <Fade >

                    <div className="row">
                        <div className={textSpacerClassName}></div>
                        <div className={textContainerClassName}>
                            {/* <p className="customHeader" style={{ textAlign: "center" }}>
                Universal apps are in.
              </p> */}

                            {/* <p className="customHeader" style={{ textAlign: "center" }}>
                <Zoom left cascade>
                  <div style={{ display: 'inline-block' }}>Universal a</div>

                </Zoom><Zoom right cascade>
                  <div style={{ display: 'inline-block' }}>pps are in.</div>
                </Zoom>
              </p> */}

                            {this.props.width > 675 ?
                                <p className="customHeader" style={{ textAlign: "center" }}>
                                    <Zoom left cascade>
                                        <span style={{ display: 'inline-block' }}>Universal a</span>

                                    </Zoom><Zoom right cascade>
                                        <span style={{ display: 'inline-block' }}>pps are in.</span>
                                    </Zoom>
                                </p>
                                :
                                this.props.width >= 540 ?
                                    <p className="customHeader" style={{ textAlign: "center" }}>
                                        <Zoom left cascade>
                                            <span style={{ display: 'inline-block' }}>Universal a</span>

                                        </Zoom><Zoom right cascade>
                                            <span style={{ display: 'inline-block' }}>pps are in.</span>
                                        </Zoom>
                                    </p>
                                    :
                                    <p className="customHeader" style={{ textAlign: "center", fontSize: "30px" }}>

                                        <Zoom left>
                                            <span style={{ display: 'inline-block' }}>Universal</span>
                                        </Zoom>

                                        <Zoom left delay={50}>
                                            <span style={{ display: 'inline-block' }}>&nbsp;apps</span>
                                        </Zoom>

                                        <Zoom left delay={100}>
                                            <span style={{ display: 'inline-block' }}>&nbsp;are</span>
                                        </Zoom>

                                        <Zoom left delay={150}>
                                            <span style={{ display: 'inline-block' }}>&nbsp;in.</span>
                                        </Zoom>

                                    </p>

                            }

                        </div>
                        <div className={textSpacerClassName}></div>
                    </div>
                </Fade>


                <div className="row" style={{ height: paragraphSpacing }}></div>


                <Fade >
                    <div className="row">
                        <div className="col-lg-3"></div>
                        <div className="col-lg-6" style={{ textAlign: "center" }}>
                            {/* <img className="img-fluid" src={UniversalAppMontage} /> */}

                            <picture className="img-fluid">
                                <source type="image/webp" srcset={UniversalAppMontageWEBP} />
                                <source type="image/jp2" srcset={UniversalAppMontageJP2} />
                                <img src={UniversalAppMontagePNG} className="img-fluid" />
                            </picture>
                        </div>
                        <div className="col-lg-3"></div>
                    </div>
                </Fade>

                <div className="row" style={{ height: "64px" }}></div>

                <Fade >
                    <div className="row">
                        <div className={textSpacerClassName}></div>
                        <div className={textContainerClassName}>

                            <p className="customTextTwo">
                                Making your app available to all users is a no brainer. We’ll go the extra mile to make sure it not only runs on all devices from iPhone SE to iMac, but also looks natural and works flawlessly.
              </p>

                        </div>
                        <div className={textSpacerClassName}></div>
                    </div>
                </Fade>


                <div className="row" style={{ height: (sectionSpacing / 2) }}></div>
                <hr />
                <div className="row" style={{ height: (sectionSpacing / 2) }}></div>


                <Fade >
                    {/* <Slide  left duration={850} fraction={1.0}> */}

                    <div className="row">
                        <div className={textSpacerClassName}></div>
                        <div className={textContainerClassName}>
                            {/* <p className="customHeader" style={{ textAlign: "center" }}>
                  We’ll get you on the App Store.
                </p> */}

                            {/* <p className="customHeader" style={{ textAlign: "center" }}>
                  <Zoom left cascade>
                    <div style={{ display: 'inline-block' }}>We’ll get you on</div>

                  </Zoom><Zoom right cascade>
                    <div style={{ display: 'inline-block' }}> the App Store.</div>
                  </Zoom>
                </p> */}

                            {this.props.width > 675 ?
                                <p className="customHeader" style={{ textAlign: "center" }}>
                                    <Zoom left cascade>
                                        <span style={{ display: 'inline-block' }}>We’ll get you on</span>

                                    </Zoom><Zoom right cascade>
                                        <span style={{ display: 'inline-block' }}> the App Store.</span>
                                    </Zoom>
                                </p>
                                :
                                this.props.width >= 540 ?
                                    <p className="customHeader" style={{ textAlign: "center" }}>
                                        <Zoom left cascade>
                                            <span style={{ display: 'inline-block' }}>We’ll get you on</span>

                                        </Zoom><Zoom right cascade>
                                            <span style={{ display: 'inline-block' }}> the App Store.</span>
                                        </Zoom>
                                    </p>
                                    :
                                    <p className="customHeader" style={{ textAlign: "center", fontSize: "30px" }}>

                                        <Zoom left>
                                            <span style={{ display: 'inline-block' }}>We'll</span>
                                        </Zoom>

                                        <Zoom left delay={50}>
                                            <span style={{ display: 'inline-block' }}>&nbsp;get</span>
                                        </Zoom>

                                        <Zoom left delay={100}>
                                            <span style={{ display: 'inline-block' }}>&nbsp;you</span>
                                        </Zoom>

                                        <Zoom left delay={150}>
                                            <span style={{ display: 'inline-block' }}>&nbsp;on</span>
                                        </Zoom>

                                        <Zoom left delay={100}>
                                            <span style={{ display: 'inline-block' }}>&nbsp;the</span>
                                        </Zoom>

                                        <Zoom left delay={100}>
                                            <span style={{ display: 'inline-block' }}>&nbsp;app</span>
                                        </Zoom>

                                        <Zoom left delay={100}>
                                            <span style={{ display: 'inline-block' }}>&nbsp;store.</span>
                                        </Zoom>

                                    </p>

                            }

                        </div>
                        <div className={textSpacerClassName}></div>
                    </div>
                    {/* </Slide> */}
                </Fade>


                <div className="row" style={{ height: paragraphSpacing }}></div>


                <div className="row">
                    <div className="col-lg-1"></div>

                    <div className="col-lg-5" style={{ textAlign: "center" }, this.props.width < 992 ? { marginBottom: "32px" } : {}}>
                        <Slide left duration={650}>
                            {/* <img className="img-fluid" style={{ maxHeight: "50vh", display: "block", margin: "auto" }} src={iPadProSetGenieAppStore} /> */}

                            <picture className="img-fluid" style={{ maxHeight: "50vh", display: "block", margin: "auto" }}>
                                <source type="image/webp" srcset={iPadProSetGenieAppStoreWEBP} />
                                <source type="image/jp2" srcset={iPadProSetGenieAppStoreJP2} />
                                <img src={iPadProSetGenieAppStorePNG} className="img-fluid" style={{ maxHeight: "50vh", display: "block", margin: "auto" }} />
                            </picture>
                        </Slide>
                    </div>

                    <div className="col-lg-5">
                        <Slide right duration={650}>
                            <p className="customTextTwo">
                                {this.morozDigital()} brings years of experience with the app launch process. From beta testing to configuring your app store page, we’re there to help.
            </p>
                        </Slide>
                    </div>

                    <div className="col-lg-1"></div>
                </div>


                <div className="row" style={{ height: (sectionSpacing / 2) }}></div>
                <hr />
                <div className="row" style={{ height: (sectionSpacing / 2) }}></div>


                <Fade >
                    {/* <Slide  right duration={850} fraction={1.0}> */}

                    <div className="row">
                        <div className={textSpacerClassName}></div>
                        <div className={textContainerClassName}>

                            {/* <p className="customHeader" style={{ textAlign: "center" }}>
                  We’ll help you get discovered.
                </p> */}

                            {/* <p className="customHeader" style={{ textAlign: "center" }}>
                  <Zoom left cascade>
                    <div style={{ display: 'inline-block' }}>We’ll help you </div>

                  </Zoom><Zoom right cascade>
                    <div style={{ display: 'inline-block' }}>get discovered.</div>
                  </Zoom>
                </p> */}

                            {this.props.width > 675 ?
                                <p className="customHeader" style={{ textAlign: "center" }}>
                                    <Zoom left cascade>
                                        <span style={{ display: 'inline-block' }}>We’ll help you </span>

                                    </Zoom><Zoom right cascade>
                                        <span style={{ display: 'inline-block' }}>get discovered.</span>
                                    </Zoom>
                                </p>
                                :
                                this.props.width >= 540 ?
                                    <p className="customHeader" style={{ textAlign: "center" }}>
                                        <Zoom left cascade>
                                            <span style={{ display: 'inline-block' }}>We’ll help you </span>

                                        </Zoom><Zoom right cascade>
                                            <span style={{ display: 'inline-block' }}>get discovered.</span>
                                        </Zoom>
                                    </p>
                                    :
                                    <p className="customHeader" style={{ textAlign: "center", fontSize: "30px" }}>

                                        <Zoom left>
                                            <span style={{ display: 'inline-block' }}>We'll</span>
                                        </Zoom>

                                        <Zoom left delay={50}>
                                            <span style={{ display: 'inline-block' }}>&nbsp;help</span>
                                        </Zoom>

                                        <Zoom left delay={100}>
                                            <span style={{ display: 'inline-block' }}>&nbsp;you</span>
                                        </Zoom>

                                        <Zoom left delay={150}>
                                            <span style={{ display: 'inline-block' }}>&nbsp;get</span>
                                        </Zoom>

                                        <Zoom left delay={100}>
                                            <span style={{ display: 'inline-block' }}>&nbsp;discovered.</span>
                                        </Zoom>

                                    </p>

                            }

                        </div>
                        <div className={textSpacerClassName}></div>
                    </div>
                    {/* </Slide> */}
                </Fade>


                <div className="row" style={{ height: paragraphSpacing }}></div>


                <div className="row">
                    <div className="col-lg-2"></div>

                    <div className="col-lg-4" style={this.props.width < 992 ? { marginBottom: "32px" } : {}}>
                        <Slide left duration={650}>
                            <p className="customTextTwo">
                                Getting your app into users’ hands involves a confluence of marketing techniques, each of which can affect and amplify the others. We combine best-practice app store optimization strategies with professional-grade analytics tools to make sure your app is seen and installed by your target audience.
            </p>
                        </Slide>
                    </div>

                    <div className="col-lg-4" style={{ textAlign: "center" }}>
                        <Slide right duration={650}>
                            {/* <img className="img-fluid" src={AppStoreConnectGraphs} /> */}

                            <picture className="img-fluid">
                                <source type="image/webp" srcset={AppStoreConnectGraphsWEBP} />
                                <source type="image/jp2" srcset={AppStoreConnectGraphsJP2} />
                                <img src={AppStoreConnectGraphsPNG} className="img-fluid" />
                            </picture>
                        </Slide>
                    </div>

                    <div className="col-lg-2"></div>
                </div>


                <div className="row" style={{ height: (sectionSpacing / 2) }}></div>
                <hr />
                <div className="row" style={{ height: (sectionSpacing / 2) }}></div>


                <Fade >
                    {/* <Slide  left duration={850} fraction={1.0}> */}

                    <div className="row">
                        <div className={textSpacerClassName}></div>
                        <div className={textContainerClassName}>

                            {/* <p className="customHeader" style={{ textAlign: "center" }}>
                  And we’ll keep you up to date.
                </p> */}

                            {/* <p className="customHeader" style={{ textAlign: "center" }}>
                  <Zoom left cascade>
                    <div style={{ display: 'inline-block' }}>And we’ll keep </div>

                  </Zoom><Zoom right cascade>
                    <div style={{ display: 'inline-block' }}>you up to date.</div>
                  </Zoom>
                </p> */}

                            {this.props.width > 675 ?
                                <p className="customHeader" style={{ textAlign: "center" }}>
                                    <Zoom left cascade>
                                        <span style={{ display: 'inline-block' }}>And we’ll keep </span>

                                    </Zoom><Zoom right cascade>
                                        <span style={{ display: 'inline-block' }}>you up to date.</span>
                                    </Zoom>
                                </p>
                                :
                                this.props.width >= 540 ?
                                    <p className="customHeader" style={{ textAlign: "center" }}>
                                        <Zoom left cascade>
                                            <span style={{ display: 'inline-block' }}>And we’ll keep </span>

                                        </Zoom><Zoom right cascade>
                                            <span style={{ display: 'inline-block' }}>you up to date.</span>
                                        </Zoom>
                                    </p>
                                    :
                                    <p className="customHeader" style={{ textAlign: "center", fontSize: "30px" }}>

                                        <Zoom left>
                                            <span style={{ display: 'inline-block' }}>And</span>
                                        </Zoom>

                                        <Zoom left delay={50}>
                                            <span style={{ display: 'inline-block' }}>&nbsp;we'll</span>
                                        </Zoom>

                                        <Zoom left delay={100}>
                                            <span style={{ display: 'inline-block' }}>&nbsp;keep</span>
                                        </Zoom>

                                        <Zoom left delay={150}>
                                            <span style={{ display: 'inline-block' }}>&nbsp;you</span>
                                        </Zoom>

                                        <Zoom left delay={100}>
                                            <span style={{ display: 'inline-block' }}>&nbsp;up</span>
                                        </Zoom>

                                        <Zoom left delay={100}>
                                            <span style={{ display: 'inline-block' }}>&nbsp;to</span>
                                        </Zoom>

                                        <Zoom left delay={100}>
                                            <span style={{ display: 'inline-block' }}>&nbsp;date.</span>
                                        </Zoom>

                                    </p>

                            }

                        </div>
                        <div className={textSpacerClassName}></div>
                    </div>
                    {/* </Slide> */}
                </Fade>


                <div className="row" style={{ height: paragraphSpacing }}></div>


                <div className="row">
                    <div className="col-lg-1"></div>

                    <div className="col-lg-5" style={this.props.width < 992 ? { marginBottom: "32px", textAlign: "center" } : { textAlign: "center" }}>
                        <Slide left duration={650}>
                            {/* <img className="img-fluid" src={AppStoreIconEdited2} style={{ maxHeight: "25vh" }} /> */}

                            <picture className="img-fluid" style={{ maxHeight: "25vh" }}>
                                <source type="image/webp" srcset={AppStoreIconEdited2WEBP} />
                                {/* <source type="image/jp2" srcset={AppStoreIconEdited2JP2} /> */}
                                <img src={AppStoreIconEdited2PNG} className="img-fluid" style={{ maxHeight: "25vh" }} />
                            </picture>
                        </Slide>
                    </div>

                    <div className="col-lg-5">
                        <Slide right duration={650}>
                            <p className="customTextTwo">
                                As existing technologies evolve and new ones emerge, user needs and expectations shift. We can help keep your app current by ensuring compatibility with the latest operating systems, integrating new system features, and responding to user feedback.
            </p>
                        </Slide>
                    </div>

                    <div className="col-lg-1"></div>
                </div>

                <div className="row" style={{ height: sectionSpacing }}></div>

                <Footer />
            </div >
        );
    }
}


const mapSizesToProps = ({ width }) => ({
    collapseButtonIsShowing: width < 992,
    width: width
})

export default withSizes(mapSizesToProps)(AppDevelopmentPage);

// export default AppDevelopmentPage;
