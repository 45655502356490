import React, { Component, useEffect } from "react";
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, DropdownItem, UncontrolledDropdown, DropdownToggle, DropdownMenu, } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import withSizes from 'react-sizes'

import BurgerMenu from './BurgerMenu'

import FrostSmallest from '../../images/frost-reduced-more-compressed.gif'

// import FrostSVG from '../../images/frost-vector-white-background-3 copy.svg'
import Logo from '../../images/logo.svg'
import LogoAlternate from '../../images/frost-alternate-flipped-rotated-adjusted.svg'
import LogoFlattened from '../../images/frost-flattened.svg'



class NavigationBar extends Component {

    constructor(props) {
        super(props);
        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.toggleError = this.toggleError.bind(this);
        this.closeNavbar = this.closeNavbar.bind(this);

        this.state = {
            isOpen: false,
            aboutDropdownIsOpen: false,
            dropdownError: false,
            // navbarClass: withSizes(({ width }) => ({ collapseButtonIsShowing: width < 992 })) ? "mx-auto navbar-right" : "mx-auto navbar-center"
        };

    }

    showSettings(event) {
        event.preventDefault();
    }

    componentDidMount() {
        // if (this.props.width >= 1200) {
        // document.getElementById('logo-gif').src = FrostSmallest + "?a=" + Math.random();
        // document.getElementById('logo-gif').src = LogoAlternate + "?a=" + Math.random();
        // }        
    }


    toggleNavbar() {
        // console.log("toggleNavbar called; current (outgoing) value of this.state.isOpen: " + this.state.isOpen)
        this.setState({
            isOpen: !this.state.isOpen,
        });
    }

    toggleError() {
        this.setState({
            dropdownError: !this.state.dropdownError
        });
    }

    closeNavbar(url) {
        // console.log("running NavigationBar.closeNavbar")
        if (url) {
            this.props.history.push({ pathname: url })
        }
        if (this.state.isOpen == true) {
            this.toggleNavbar();
        }
    }



    render() {

        // console.log("running NavigationBar.render")

        if (this.state.isOpen) {
            // console.log("this.state.isOpen == true")
            document.body.classList.add('frozen')
        } else {
            // console.log("this.state.isOpen == false")
            document.body.classList.remove('frozen')
        }

        // OLD
        // const navbarClass = this.props.collapseButtonIsShowing ? "mx-auto navbar-right" : "mx-auto navbar-center"

        // NEW
        // const navbarClass = this.props.collapseButtonIsShowing ? "ml-auto" : "mx-auto navbar-center"

        // NEWER
        const navbarClass = this.props.collapseButtonIsShowing ? "ml-auto" : "ml-auto"
        const navbarTextAlign = this.props.collapseButtonIsShowing ? "right" : "center"

        const navbarBorderStyle = this.props.collapseButtonIsShowing ? "hidden" : "solid"
        const dropdownTextAlign = this.props.collapseButtonIsShowing ? "right" : "left"

        return (

            <>

                <Navbar dark expand="lg">

                    <NavbarBrand onClick={this.closeNavbar} tag={Link} to="/">

                        <div style={{ display: "flex" }}>

                            <img id="logo-gif" style={{ margin: "6% 20px 5% 0px" }} src={FrostSmallest}></img>
                            {/* <img id="logo-gif" style={{ margin: "6% 20px 5% 0px" }} src={Logo}></img> */}


                            <div style={{ paddingTop: "6%", paddingBottom: "0%" }}>
                                <h1 style={{ paddingTop: "0px", paddingBottom: "0px" }} className="navbarHeaderText">MOROZ <span style={{ fontWeight: "600" }}>DIGITAL</span></h1>
                                <hr style={{ backgroundColor: "white", margin: "0% 10% 2% 10%" }} />

                                {/* <div style={{paddingLeft: "15%", paddingRight: "15%"}}>
                                    <img style={{ margin: "auto", display: "block" }} src={LogoFlattened}></img>
                                </div> */}

                                <p className="navbarSubtitleText" style={{ textAlign: "center", display: "block" }}>web&nbsp;&nbsp;&&nbsp;&nbsp;mobile&nbsp;&nbsp;design</p>

                            </div>

                        </div>

                    </NavbarBrand>

                    {this.props.width >= 1200 ?
                        <>
                            <NavbarToggler onClick={this.toggleNavbar} />
                            <Collapse isOpen={this.state.isOpen} navbar className="w-100">

                                <Nav className={navbarClass} /*vertical*/ navbar style={{ paddingBottom: "2px", borderStyle: navbarBorderStyle, borderWidth: "0px 0px 2px 0px", borderColor: "rgba(225, 226, 221, 1.0)", textAlign: navbarTextAlign }}>

                                    <NavItem className="navLinkText nowrap px-2">
                                        <NavLink className="nowrap textLink" style={{ color: 'rgba(225, 226, 221, 1.0)' }} /*onClick={this.closeNavbar}*/ tag={Link} to="/app-development">app development</NavLink>
                                    </NavItem>

                                    <NavItem className="navLinkText nowrap px-2">
                                        <NavLink className="nowrap textLink" style={{ color: 'rgba(225, 226, 221, 1.0)' }} /*onClick={this.closeNavbar}*/ tag={Link} to="/web-design">web design</NavLink>
                                    </NavItem>

                                    <UncontrolledDropdown className="navLinkText nowrap px-2" nav inNavbar >
                                        <DropdownToggle nav caret className="textLink" style={{ color: "rgba(225, 226, 221, 1.0)" }}>about</DropdownToggle>
                                        <DropdownMenu className="bg-dark" style={{ width: "200px", float: "right" }}>

                                            <DropdownItem className="navLinkText bg-dark" style={{ color: "rgba(225, 226, 221, 1.0)", backgroundColor: "black", textAlign: dropdownTextAlign }} tag={Link} to="/about" /*onClick={e =>
                                                this.closeNavbar("/about")}*/>
                                                About Moroz Digital
                                            </DropdownItem>

                                            <DropdownItem className="navLinkText bg-dark" style={{ color: "rgba(225, 226, 221, 1.0)", backgroundColor: "black", textAlign: dropdownTextAlign }} tag={Link} to="/faq" /*onClick={e =>
                                                this.closeNavbar("/faq")}*/>
                                                FAQ
                                            </DropdownItem>

                                         </DropdownMenu>
                                    </UncontrolledDropdown>

                                    <NavItem className="navLinkText nowrap px-2" /*style={{ marginTop: '-8px', marginBottom: '-8px' }}*/>
                                        {/* <NavLink className="nowrap" href="/contact" style={{ color: 'white' }}>contact</NavLink> */}
                                        <NavLink className="nowrap textLink" style={{ color: 'rgba(225, 226, 221, 1.0)' }} /*onClick={this.closeNavbar}*/ tag={Link} to="/contact">contact</NavLink>
                                    </NavItem>
                                </Nav>

                            </Collapse>
                        </>
                        :
                        // null
                        <BurgerMenu />

                    }

                </Navbar>

            </>

        );

    }
}

const mapSizesToProps = ({ width }) => ({
    collapseButtonIsShowing: width < 992,
    width: width
})

// export default connect(
//   mapStateToProps,
//   {logoutUser}
// )(NavigationBar);

export default withRouter(withSizes(mapSizesToProps)(NavigationBar));
