import React, { Component } from "react";
// import { Link } from "react-router-dom";
import { Nav, Navbar, NavbarToggler, NavItem, NavLink, Collapse, CardBody, CardHeader, Card, Container, Button, ButtonGroup } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Slide, Fade, Zoom } from 'react-reveal';
import { withSizes } from 'react-sizes';
import Helmet from 'react-helmet';

import Footer from '../layout/Footer';




class FAQPage extends Component {

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.morozDigital = this.morozDigital.bind(this);
        this.listenToScroll = this.listenToScroll.bind(this);

        this.state = {
            navbarIsOpen: false,
            scrollPosition: 0,
            contactButtonOpacity: 0,
            isViewingAppDevelopmentQuestions: false,
            collapse: null,
            appDevelopmentCards: [0, 1, 2, 3],
            webDevelopmentCards: [0, 1, 2, 3, 4, 5, 6, 7, 8]
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.listenToScroll)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.listenToScroll)
    }

    listenToScroll = () => {
        const winScroll =
            document.body.scrollTop || document.documentElement.scrollTop

        const height =
            document.documentElement.scrollHeight -
            document.documentElement.clientHeight

        const scrolled = winScroll / height

        var opacity = 0;

        if (scrolled < 0.1) {
            opacity = 0;
        } else if ((scrolled >= 0.1) && (scrolled < 0.2)) {
            opacity = (scrolled - 0.1) * 10
        } else {
            opacity = 1
        }

        this.setState({
            scrollPosition: scrolled,
            contactButtonOpacity: opacity
        })

    }

    toggle(e) {
        console.log("function toggle called; value of event: " + e.target.dataset.event)
        let event = e.target.dataset.event;

        // this.setState({ collapse: this.state.collapse === Number(event) ? 0 : Number(event) });
        this.setState({ collapse: this.state.collapse === Number(event) ? null : Number(event) });
        console.log("new value of this.state.collapse: " + this.state.collapse)
    }

    toggleQuestionType() {
        this.setState({
            isViewingAppDevelopmentQuestions: !this.state.isViewingAppDevelopmentQuestions,
            collapse: null
        })
    }

    morozDigital() {
        return (
            <span style={{ fontWeight: '900' }}>Moroz&nbsp;Digital</span>
        )
    }

    renderAppDevelopmentQuestions() {

        const questionFontSize = this.props.width > 992 ? "23px" : "20px"
        const answerFontSize = this.props.width > 992 ? "20px" : "17px"

        const cards = [
            {
                question: "How long does it take to build an app?",
                answer: "The time it takes to create a custom mobile or desktop app can range from several weeks to six months or more, depending on project requirements and scope."
            },
            {
                question: "I have an idea for an app. What is the process of turning it into a reality?",
                answer: "The process begins with an initial meeting, typically over Zoom or FaceTime. We'll get to know you and your business, your end users and competitors, and your goals. Together, we'll arrive at a shared understanding of the role an app will play in growing your business, along with the exact features and appearance you need. After laying this groundwork, we'll delve further into planning by collecting or creating any content required, developing a strategy for communication with your user base, creating data models, and drafting a timeline. Once planning is complete, we proceed into the design and development phase, during which the app's user interface takes shape and its functions are implemented in code. Toward the end of this stage, we will provide you with a prototype which can be tested and evaluated on your own devices, and can make refinements based on your feedback. After we have made any adjustments necessary, we will rigorously test the product in real-world use scenarios. Once testing is complete, we are ready to launch your new app!"
            },
            {
                question: "How much does an app cost?",
                answer: "Each project is different, depending on the scope of the project and the exact features and functionality you need. Please contact us for a quote."
            },
            {
                question: "My business already has a website and a user base, and I’d like to add a mobile app. Can you do that?",
                answer: "Yes! Linking an app to an existing website's backing server is a common request, and is straightforward in most cases."
            },
            {
                question: "What technologies do you use to build apps?",
                answer: "We build apps for the Apple ecosystem (iOS, iPadOS, macOS, and watchOS) using Swift and Objective-C. For Android apps, we use React Native."
            },
            {
                question: "Can you help me update my existing app?",
                answer: "Most likely yes! Depending on the technologies your existing app uses and the nature of the updates you would like to make, we can either update it or rebuild it. In either case, the cost is often somewhat less than that of a new app."
            },
            {
                question: "Is my app backed up?",
                answer: "Yes. Each app's codebase is stored in a dedicated private GitHub repository. We also maintain local backups for added redundancy."
            },
            {
                question: "Who owns my app?",
                answer: "You do! We are happy to perform maintenance and updates, but you own all code and content."
            },

        ]


        return (
            <>

                <div style={{ height: "32px" }}></div>

                {
                    cards.map((card, index) => {
                        return (
                            <Card style={{ marginBottom: '1rem', cursor: "pointer" }} key={index}>
                                <CardHeader style={{ fontFamily: "Raleway", fontSize: questionFontSize, fontWeight: "500", color: "black" }} onClick={this.toggle} data-event={index}>{card.question}</CardHeader>
                                <Collapse isOpen={this.state.collapse === index}>
                                    <CardBody style={{ fontFamily: "Raleway", fontSize: answerFontSize, fontWeight: "400", color: "black" }}>{card.answer}</CardBody>
                                </Collapse>
                            </Card>
                        )
                    })
                }
            </>
        )
    }

    renderWebDesignQuestions() {

        const questionFontSize = this.props.width > 992 ? "23px" : "20px"
        const answerFontSize = this.props.width > 992 ? "20px" : "17px"

        const cards = [
            {
                question: "How long does it take to build a website?",
                answer: "Assuming all content is sourced and ready to publish, many websites can be completed within two to three weeks. Highly interactive sites, eCommerce solutions, and other more complex projects can take longer."
            },
            {
                question: "What are the steps involved in creating a website?",
                answer: "We begin the process with an initial meeting, during which we'll get to know you, your business, and your goals. We'll talk about your clients and competitors, the markets you operate in, and the role a website will play in your overall business model. Once we have clearly defined the project's objectives and technical requirements, we will begin the process of collecting, creating, and organizing your site's content. With the content in order, we will enter the design and development stage. We'll craft your site's appearance and user interface, and implement its features and functionality. During this phase, we'll provide a link where you can watch as your site takes shape. Once development is complete, we will rigorously test your site, making any final aesthetic or functional changes needed. When this is done, your site is ready to launch!"
            },
            {
                question: "How much does a website cost?",
                answer: "Our web design services start at $1500 for a basic business site, and extend upward depending on your specific needs. For smaller projects, we also offer our services on an hourly basis at a rate of $80/hour."
            },
            {
                question: "What technologies do you use to build websites?",
                answer: "The primary technologies we use include HTML5, CSS3, Javascript, React, Node.js, and MongoDB. We can also integrate your site with one of several content management systems to allow you to make updates yourself."
            },
            {
                question: "Can you help me update my existing website?",
                answer: "Most likely yes! Depending on the technologies your existing site uses and the nature of the updates you would like to make, we can either update it in place or completely rebuild it. In either case, the cost is often somewhat less than that of a new site."
            },
            {
                question: "Can you help me write copy and create content for my website?",
                answer: "Yes! We are happy to help with content creation, copywriting, and editing."
            },
            {
                question: "Where will my website be hosted, and how much does hosting cost?",
                answer: "For static sites without significant interactive features, we will recommend one of several established, reliable hosting providers. For React sites with custom backends, we use Heroku exclusively. Operating costs for a typical business website can range from $5-25 per month, with most sites falling toward the lower end of the spectrum. This estimate includes hosting, domain registration, and DNS services."
            },
            {
                question: "Is my website backed up?",
                answer: "Yes. Each site's codebase is stored in a dedicated private GitHub repository. We also maintain local backups for added redundancy."
            },
            {
                question: "Who owns my website?",
                answer: "You do! We are happy to maintain and update your site, but you own all code and content."
            },

        ]
        return (
            <>
                <div style={{ height: "32px" }}></div>

                {
                    cards.map((card, index) => {
                        return (
                            <Card style={{ marginBottom: '1rem', cursor: "pointer" }} key={index}>
                                <CardHeader style={{ fontFamily: "Raleway", fontSize: questionFontSize, fontWeight: "500", color: "black" }} onClick={this.toggle} data-event={index}>{card.question}</CardHeader>
                                <Collapse isOpen={this.state.collapse === index}>
                                    <CardBody style={{ fontFamily: "Raleway", fontSize: answerFontSize, fontWeight: "400", color: "black" }}>{card.answer}</CardBody>
                                </Collapse>
                            </Card>
                        )
                    })
                }
            </>
        )
    }

    render() {

        const containerClass = window.innerWidth > 1000 ? "" : ""

        var mobilePhotosContainerClassName
        var mobilePhotosSpacerClassName

        const paragraphSpacing = this.props.width < 992 ? 32 : 64
        const sectionSpacing = this.props.width < 992 ? 90 : 180

        if (this.props.width < 992) {
            mobilePhotosSpacerClassName = "col-1"
            mobilePhotosContainerClassName = "col-5"
        } else {
            mobilePhotosSpacerClassName = "col-sm-3"
            mobilePhotosContainerClassName = "col-sm-3"
        }

        var textContainerClassName
        var textSpacerClassName

        var imageContainerClassName
        var imageSpacerClassName

        if (this.props.width < 1450) {
            textSpacerClassName = "col-lg-1"
            textContainerClassName = "col-lg-10"

            imageSpacerClassName = "col-lg-1"
            imageContainerClassName = "col-lg-10"
        } else {
            textSpacerClassName = "col-lg-2"
            textContainerClassName = "col-lg-8"

            imageSpacerClassName = "col-lg-3"
            imageContainerClassName = "col-lg-6"
        }


        return (
            <div className="p-2">

                <Helmet bodyAttributes={{ style: "background-color: rgb(3, 60, 79)" }}>
                    <title>Frequently Asked Questions – Moroz Digital</title>
                </Helmet>

                {/* <div style={{ height: paragraphSpacing }}></div> */}
                <div style={{ height: "32px" }}></div>

                <p className="customHeader" style={{ textAlign: "center" }}>
                    Frequently Asked Questions
                </p>

                <div style={{ height: "32px" }}></div>

                <div className="row">
                    <div className="col-lg-12">
                        <div style={{ textAlign: "center" }}>
                            <ButtonGroup>
                                {/* <Button color="primary" onClick={e => this.setState({ isViewingAppDevelopmentQuestions: false })} active={this.state.isViewingAppDevelopmentQuestions == false}>Web Design</Button>
                                <Button color="primary" onClick={e => this.setState({ isViewingAppDevelopmentQuestions: true })} active={this.state.isViewingAppDevelopmentQuestions == true}>App Development</Button> */}

                                <Button color="primary" onClick={e => this.toggleQuestionType()} active={this.state.isViewingAppDevelopmentQuestions == false}>Web Design</Button>
                                <Button color="primary" onClick={e => this.toggleQuestionType()} active={this.state.isViewingAppDevelopmentQuestions == true}>App Development</Button>

                            </ButtonGroup>
                        </div>
                    </div>
                </div>

                <div style={{ height: "32px" }}></div>

                <div className="row">
                    <div className="col-lg-2"></div>

                    <div className="col-lg-8">

                        {this.state.isViewingAppDevelopmentQuestions ?
                            this.renderAppDevelopmentQuestions()
                            :
                            this.renderWebDesignQuestions()
                        }

                    </div>

                    <div className="col-lg-2"></div>
                </div>

                <div style={{ height: "64px" }}></div>



                <Footer />
            </div>
        );
    }
}


const mapSizesToProps = ({ width }) => ({
    collapseButtonIsShowing: width < 992,
    width: width
})

export default withSizes(mapSizesToProps)(FAQPage)
