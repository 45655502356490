import React from 'react'
import Menu from 'react-burger-menu/lib/menus/stack'
import { Link } from 'react-router-dom';
import { NavItem, NavLink, Dropdown, DropdownItem, UncontrolledDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { withSizes } from 'react-sizes';
import { Motion, spring, presets } from 'react-motion'
import { Slide, Fade, Zoom } from 'react-reveal';

// import Logo from '../../images/final-frame-right-half.png'

class BurgerMenu extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            toggle: false,
            dropdownIsOpen: false
        }
    }


    showSettings(event) {
        event.preventDefault();
    }

    handleClick() {
        this.setState({ toggle: !this.state.toggle })
    }

    toggleDropdown() {
        this.setState({
            dropdownIsOpen: !this.state.dropdownIsOpen
        })
    }

    render() {    
                
        if (this.state.toggle) {
            document.body.classList.add("frozen")            
        } else {
            document.body.classList.remove("frozen")
        }
        

        // const top = this.props.width < 768 ? "11.1vw" : "85px"
        const a = 18.5 / this.props.width * 100
        const top = this.props.width < 768 ? "calc(8.15vw + 0.52%)" : "85px"
        var fontSize

        if (this.props.width < 768) {
            if (this.props.width >= 575) {
                fontSize = "6.5vw"
            } else {
                fontSize = "37px"
            }

        } else {
            fontSize = "50px"
        }        

        const burger = (menuIsOpen) => {

            const style = {
                overflow: 'visible',
                cursor: 'pointer',
                position: menuIsOpen ? "fixed" : "absolute",
                zIndex: "1101",
                // top: "85px",
                right: "20px",
                // disable touch highlighting on devices
                WebkitTapHighlightColor: "rgba(0,0,0,0)",
                fontSize: fontSize,
                color: "rgba(225, 226, 221, 1.0)",
            }

            return (
                <svg
                    viewBox="0 0 96 96"
                    height="1em"
                    onClick={this.handleClick.bind(this)}
                    style={style}
                >
                    <Motion
                        style={{
                            x: spring(this.state.toggle ? 1 : 0, presets.wobbly),
                            y: spring(this.state.toggle ? 0 : 1, presets.wobbly),
                        }}
                    >
                        {({ x, y }) =>
                            <g
                                id="navicon"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="14"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <line
                                    transform={`translate(${x * 12}, ${x * -7}) rotate(${x * 45}, 7, 26)`}
                                    x1="7" y1="26" x2="89" y2="26"
                                />
                                <line
                                    transform={`translate(${x * 12}, ${x * 7}) rotate(${x * -45}, 7, 70)`}
                                    x1="7" y1="70" x2="89" y2="70"
                                />
                                <line
                                    transform={`translate(${x * -96})`}
                                    opacity={y}
                                    x1="7" y1="48" x2="89" y2="48"
                                />
                            </g>
                        }
                    </Motion>
                </svg>
            )

        }

        return (
            <>

                {this.props.width < 1200 ?
                    <>
                        {burger(this.state.toggle)}                        

                        <Menu isOpen={this.state.toggle} onClose={() => { this.handleClick() }} width={"100vw"} disableAutoFocus right>

                            {/* <div style={{ outline: "none", listStyleType: "none", marginTop: "25vh", marginBottom: "35vh" }}> */}

                            <NavItem className="navLinkText nowrap px-2" style={{marginTop: "15vh"}}>
                                <NavLink className="nowrap textLink" style={{ color: 'rgba(225, 226, 221, 1.0)', fontSize: "30px", display: "inline-block" }} tag={Link} to="/" onClick={() => { this.handleClick() }}>Home</NavLink>
                            </NavItem>
                            <NavItem className="navLinkText nowrap px-2">
                                <NavLink className="nowrap textLink" style={{ color: 'rgba(225, 226, 221, 1.0)', fontSize: "30px", display: "inline-block" }} tag={Link} to="/app-development" onClick={() => { this.handleClick() }}>App Development</NavLink>
                            </NavItem>
                            <NavItem className="navLinkText nowrap px-2">
                                <NavLink className="nowrap textLink" style={{ color: 'rgba(225, 226, 221, 1.0)', fontSize: "30px", display: "inline-block" }} tag={Link} to="/web-design" onClick={() => { this.handleClick() }}>Web Design</NavLink>
                            </NavItem>

                            <UncontrolledDropdown className="navLinkText nowrap px-2" style={{ display: "inline-block" }} nav inNavbar >
                                <DropdownToggle nav caret className="textLink" style={{ color: "rgba(225, 226, 221, 1.0)", fontSize: "30px", display: "inline-block" }}>About</DropdownToggle>

                                <DropdownMenu className="bg-light animatedDropdown">

                                    <DropdownItem className="navLinkText bg-light animatedDropdownItem" tag={Link} to="/about" style={{ color: "black", backgroundColor: "black", textAlign: "left" }} onClick={() => { this.handleClick() }}>
                                        About Moroz Digital
                                        </DropdownItem>

                                    <DropdownItem className="navLinkText bg-light animatedDropdownItem" tag={Link} to="/faq" style={{ color: "black", backgroundColor: "black", textAlign: "left" }} onClick={() => { this.handleClick() }}>
                                        FAQ
                                        </DropdownItem>

                                </DropdownMenu>

                            </UncontrolledDropdown>

                            <NavItem className="navLinkText nowrap px-2" style={{marginBottom: "15vh"}}>
                                <NavLink className="nowrap textLink" style={{ color: 'rgba(225, 226, 221, 1.0)', fontSize: "30px", display: "inline-block" }} tag={Link} to="/contact" onClick={() => { this.handleClick() }}>Contact</NavLink>
                            </NavItem>
                            {/* </div> */}
                        </Menu>
                    </>
                    :
                    null
                }

            </>
        );
    }
}

const mapSizesToProps = ({ width }) => ({
    collapseButtonIsShowing: width < 992,
    width: width
})

export default withSizes(mapSizesToProps)(BurgerMenu);

// export default BurgerMenu