import axios from "axios";
// import setAuthToken from "../utils/setAuthToken";
// import jwt_decode from "jwt-decode";
// import { connect } from 'react-redux';
import { decrementProgress, incrementProgress } from './progress';

import { GET_ERRORS } from "./types";

// Update User
export const submitContactForm = (submission, completion) => dispatch => {
    
    console.log('contactActions.submitContactForm called; value of submission:');
    console.log(submission);

    const url = '/api/contact'

    // turn on spinner
    dispatch(incrementProgress());

    // NEW

    axios.post(url, submission).then(res => {
        dispatch(decrementProgress());
        completion(res.data)
    }).catch(err => {
        dispatch(decrementProgress());
        completion(err.data)
    })

}