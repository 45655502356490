import React, { Component } from "react";

import MorozDigitalLogo from '../../images/logo-smaller.png'

class Footer extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let photoCredit = this.props.photoCredit;

        let backgroundColor = this.props.backgroundColor;
        if (backgroundColor === undefined) { backgroundColor = 'rgba(0, 0, 0, 0)' }

        return (

            <div className="row" style={{ padding: "0px" }}>
                <div className="footer" style={{ backgroundColor: backgroundColor }}>
                    <footer>
                        <div className="text-center py-4">
                            <small>
                                <p className="customTextTwo" style={{ fontSize: "12px", padding: "0px", margin: "0px", textAlign: "center" }}>
                                    {photoCredit !== undefined ? <>Photo credit: {photoCredit}</> : <></>}
                                    {/* Copyright &copy; 2020 Moroz Digital */}

                                    <div className="morozDigitalLogo">
                                        <p className="customTextTwo" style={{ fontSize: "14px", padding: "0px", margin: "0px", textAlign: "center" }}>
                                            <a href="https://www.morozdigital.com" className="morozDigitalLogoLink">
                                                <span style={{ paddingTop: "0.2em" }}>Copyright &copy; 2021&nbsp;&nbsp;</span>
                                                <img src={MorozDigitalLogo} style={{ width: "3em" }} />
                                                <span style={{ fontFamily: "Raleway", fontSize: "14px", paddingTop: "0.2em" }}>&nbsp;&nbsp;&nbsp;MOROZ <span style={{ fontWeight: "600" }}>DIGITAL</span></span>
                                            </a>
                                        </p>
                                    </div>

                                </p>
                            </small>
                        </div>
                    </footer>
                </div>
            </div>
        );
    }
}

export default Footer;
