import React, { Component } from "react";
import { Nav, Navbar, NavbarToggler, NavItem, NavLink, Collapse, Container, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';
import { Fade } from 'react-reveal';
import { withSizes } from 'react-sizes';

import PhotoGallery from '../pages/PhotoGallery';
import NavigationBar from '../layout/NavigationBar'
import BurgerMenu from '../layout/BurgerMenu'
import Footer from '../layout/Footer';


import iPadProChartLandscape from '../../images/ipad-pro-chart-landscape-reduced.png';
import iPadProNewSchedule from '../../images/ipad-pro-12.9-new-schedule-reduced.png';
import iPadProEditAvailabilityRange from '../../images/ipad-pro-12.9-availabird-edit-availability-range-reduced.png'

import charlotteMorozRegretAndTimeTravel from '../../images/charlotte-regret-and-time-travel-new-cropped.jpg';
// import setGenieHomePage from '../../images/set-genie-homepage.jpg';
import shweebeeRulesPage from '../../images/shweebee-rules-reduced-new-cropped.jpg';
import JakeWhitesellHomePage from '../../images/jake-whitesell-homepage-reduced.jpg';
import MarshallDominguezHomePage from '../../images/marshall-homepage-reduced.jpg';

import MarshallDominguezHomePageMacBookAndIphonePNG from '../../images/marshall-homepage-macbook-iphone-new-small-reduced.png'
import MarshallDominguezHomePageMacBookAndIphoneJP2 from '../../images/marshall-homepage-macbook-iphone-new-small-reduced.jp2'
import MarshallDominguezHomePageMacBookAndIphoneWEBP from '../../images/marshall-homepage-macbook-iphone-new-small-reduced.webp'

import BlueHouseMushroomJPG from '../../images/blue-house-mushroom.jpg'
import AbodeWellnessJPG from '../../images/abode-wellness-5.jpg'
import AbodeWellnessFaqJPG from '../../images/abode-wellness-faq.jpg'

import MacBookAirCreatingSetlistDark from '../../images/macbook-air-creating-setlist-dark-reduced.png';
import iPhoneXGraphs from '../../images/iphone-x-graphs-reduced.png';
import iPhoneXChordPro from '../../images/iphone-x-chordpro-reduced.png';





class HomePage extends Component {

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);

        this.state = {
            navbarIsOpen: false,
            scrollPosition: 0,
            contactButtonOpacity: 0,
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.listenToScroll)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.listenToScroll)
    }

    listenToScroll = () => {
        const winScroll =
            document.body.scrollTop || document.documentElement.scrollTop

        const height =
            document.documentElement.scrollHeight -
            document.documentElement.clientHeight

        const scrolled = winScroll / height

        var opacity = 0;

        if (scrolled < 0.1) {
            opacity = 0;
        } else if ((scrolled >= 0.1) && (scrolled < 0.2)) {
            opacity = (scrolled - 0.1) * 10
        } else {
            opacity = 1
        }

        this.setState({
            scrollPosition: scrolled,
            contactButtonOpacity: opacity
        })

    }

    toggle() {
        this.setState({
            navbarIsOpen: !this.state.navbarIsOpen
        })
    }

    render() {

        const containerClass = window.innerWidth > 1000 ? "" : ""

        var margin
        if (this.props.width < 992) {
            margin = this.props.width / 190
        } else if (this.props.width < 1601) {
            margin = this.props.width / 215
        } else {
            margin = this.props.width / 265
        }

        // var margin = 2
        // if (this.props.width == 1680) { margin = 6.3 }


        const appDevelopmentPhotos = [
            {
                src: iPhoneXGraphs,
                width: 0.55422185,
                height: 1,
                title: "Visualizing data with Set Genie."
            },
            {
                src: iPadProChartLandscape,
                width: 1,
                height: 0.76648081,
                title: "Viewing a chart with Set Genie."
            },
            {
                src: iPadProEditAvailabilityRange,
                width: 0.76648081,
                height: 1,
                title: "Editing a client's availability with Availabird."
            },
            {
                src: iPadProNewSchedule,
                width: 1,
                height: 0.76648081,
                title: "Creating a new schedule with Availabird."
            },
            {
                src: MacBookAirCreatingSetlistDark,
                width: 1,
                height: 0.53602305,
                title: "Creating a setlist with Set Genie."
            },
            {
                src: iPhoneXChordPro,
                width: 0.55422185,
                height: 1,
                title: "Viewing a ChordPro file with Set Genie."
            },
        ];

        const webDesignPhotos = [
            {
                src: BlueHouseMushroomJPG,
                width: 1493,
                height: 3788,
                title: "bluehousemushroom.com: built with Builder.io, React, NodeJS, and Bootstrap"
            },
            {
                src: JakeWhitesellHomePage,
                width: 1680,
                height: 964,
                title: "jakewhitesell.com: built with React, MongoDB, NodeJS, and Bootstrap"
            },
            {
                src: shweebeeRulesPage,
                width: 1493,
                height: 1023,
                title: "shweebee.com: built with React, MongoDB, NodeJS, and Bootstrap"
            },
            // {
            //     src: AbodeWellnessJPG,
            //     width: 1,
            //     height: 1.31,
            //     title: "abodewellnessvt.com: built with Builder.io, React, NodeJS, and Bootstrap"
            // },    
            {
                src: AbodeWellnessFaqJPG,
                width: 1493,
                height: 944,
                title: "abodewellnessvt.com: built with Builder.io, React, NodeJS, and Bootstrap"
            },


            // {
            //     src: charlotteMorozRegretAndTimeTravel,
            //     width: 1.70040486,
            //     height: 1,
            //     title: "charlottemoroz.com: built with HTML5, CSS, JavaScript, PHP, and Bootstrap"
            // },
            {
                src: charlotteMorozRegretAndTimeTravel,
                width: 1.738,
                height: 1,
                title: "charlottemoroz.com: built with HTML5, CSS, JavaScript, PHP, and Bootstrap"
            },
            // {
            //     src: MarshallDominguezHomePage,
            //     width: 1.70040486,
            //     height: 1,
            //     title: "marshalldominguez.com: built with React, MongoDB, NodeJS, Bootstrap, and PayPal integration"
            // },



        ];


        const inTheTropics = "background-color: rgb(60, 153, 163)"
        const bellBottomBlues = "background-color: rgb(57, 108, 141)"
        const adriaticSea = "background-color: rgb(2, 71, 100)"
        const ruddy = "background-color: rgb(170, 89, 69)"
        const spaDay = "background-color: rgb(95, 164, 178)"
        const darkHarbor = "background-color: rgb(30, 73, 81)"
        const paperLantern = "background-color: rgb(172, 64, 48)"
        const egyptianClay = "background-color: rgb(170, 89, 69)"
        const frenchToile = "background-color: rgb(162, 177, 184)"
        const hiddenSaphire = "background-color: rgb(3, 60, 79)"

        var textContainerClassName
        var textSpacerClassName

        var imageContainerClassName
        var imageSpacerClassName

        if (this.props.width < 1450) {
            textSpacerClassName = "col-lg-1"
            textContainerClassName = "col-lg-10"

            imageSpacerClassName = ""
            imageContainerClassName = "col-lg-12"
        } else {
            textSpacerClassName = "col-lg-2"
            textContainerClassName = "col-lg-8"

            imageSpacerClassName = "col-lg-2"
            imageContainerClassName = "col-lg-8"
        }

        return (

            // <div id="outer-container" className="p-2" style={{ height: "100%", overflow: "auto", padding: "env(safe-area-inset-top, 20px) env(safe-area-inset-right, 20px) env(safe-area-inset-bottom, 20px) env(safe-area-inset-left, 20px)" }}>
            <div className="p-2">
                <Helmet bodyAttributes={{ style: "background: linear-gradient(180deg, rgb(3, 60, 79) 0%, rgb(2, 71, 100) 33%, rgb(57, 108, 141) 66%)" }}>
                    <title>Web Design & Mobile App Development – Burlington, VT – Moroz Digital</title>
                </Helmet>
                {/* <Helmet /> */}

                {/* <NavigationBar /> */}

                {/* <div id="page-wrap" className="p-1"> */}

                <Button style={{ opacity: this.state.contactButtonOpacity, backgroundColor: "rgb(60, 153, 163)", borderColor: "rgb(60, 153, 163)" }} disabled={this.state.contactButtonOpacity > 0 ? false : true} tag={Link} to="/contact" className="floating-button" color="primary">Tell us about your project!</Button>



                <div className="row" style={{ height: "48px" }}></div>

                <div className="row">
                    <div className={textSpacerClassName}></div>
                    <div className={textContainerClassName}>

                        <Fade >

                            <p className="customTextTwo">
                                <span style={{ fontWeight: '900' }}>Moroz&nbsp;Digital</span> builds innovative, sophisticated iOS and macOS apps and beautiful, mobile-friendly websites. We value compelling design language, artistic use of space, and exacting attention to details of form and function.
                            </p>

                        </Fade>


                    </div>
                    <div className={textSpacerClassName}></div>
                </div>

                <div className="row" style={{ height: "64px" }}></div>

                <div className="row">
                    <div className="col-xl-12">
                        <Fade >
                            <p className="customTextTwo" style={{ textAlign: "center", fontSize: "35px" }}>
                                Web Design
                            </p>
                        </Fade>
                    </div>
                </div>

                <div className="row" style={{ height: "32px" }}></div>

                <div className="row">
                    <div className={textSpacerClassName}></div>
                    <div className={textContainerClassName}>

                        <Fade >
                            <p className="customTextTwo">
                                We build immersive, captivating websites that draw your audience in. We emphasize thoughtful layouts, sharp typography, and clean code.
                            </p>
                        </Fade>

                    </div>
                    <div className={textSpacerClassName}></div>
                </div>

                <div className="row" style={{ height: "64px" }}></div>

                <div className="row">
                    <div className={this.props.width > 1600 ? "col-lg-2" : "col-lg-1"}></div>
                    <div className={this.props.width > 1600 ? "col-lg-8" : "col-lg-10"}>
                        <Fade >
                            <PhotoGallery photos={webDesignPhotos} columnMode={true} margin={margin} />
                        </Fade>
                    </div>
                    <div className={this.props.width > 1600 ? "col-lg-2" : "col-lg-1"}></div>
                </div>

                <div className="row" style={{ height: "64px" }}></div>

                <div className="row">
                    <div className={textSpacerClassName}></div>
                    <div className={textContainerClassName}>

                        <Fade >
                            <p className="customTextTwo">
                                Our responsive sites are designed to look great on all devices. We’ll work with you to determine the ideal content flow, and how the layout should adapt to smaller screen sizes.
                            </p>
                        </Fade>

                    </div>
                    <div className={textSpacerClassName}></div>
                </div>

                <div className="row" style={{ height: "64px" }}></div>

                <div className="row">
                    <div className={imageSpacerClassName}></div>
                    <div className={imageContainerClassName} style={{ textAlign: "center" }}>
                        <Fade >
                            {/* <img src={MarshallDominguezHomePageMacBookAndIphone} className="img-fluid" /> */}

                            <picture className="img-fluid">
                                <source type="image/webp" srcset={MarshallDominguezHomePageMacBookAndIphoneWEBP} />
                                <source type="image/jp2" srcset={MarshallDominguezHomePageMacBookAndIphoneJP2} />
                                <img src={MarshallDominguezHomePageMacBookAndIphonePNG} className="img-fluid" />
                            </picture>
                        </Fade>
                    </div>
                    <div className={imageSpacerClassName}></div>
                </div>

                <div className="row" style={{ height: "64px" }}></div>

                <div className="row">
                    <div className="col text-center">
                        <Fade >
                            <Button tag={Link} to="/web-design" className="justify-content-center" style={{ borderColor: "rgb(60, 153, 163)", backgroundColor: "rgb(60, 153, 163)" }}>Learn More About Web Design</Button>
                        </Fade>
                    </div>
                </div>

                <div className="row" style={{ height: "64px" }}></div>
                <hr />
                <div className="row" style={{ height: "64px" }}></div>

                <div className="row">
                    <div className="col-xl-12">
                        <Fade >
                            <p className="customTextTwo" style={{ textAlign: "center", fontSize: "35px" }}>
                                App Development
                            </p>
                        </Fade>
                    </div>
                </div>

                <div className="row" style={{ height: "32px" }}></div>

                <div className="row">
                    <div className={textSpacerClassName}></div>
                    <div className={textContainerClassName}>

                        <Fade >
                            <p className="customTextTwo">
                                An app is an opportunity. In an increasingly connected world, it’s a personal link to your customers – a way for them to keep a direct gateway to your brand in their pocket. <span style={{ fontWeight: '900' }}>Moroz&nbsp;Digital</span> creates streamlined, elegant mobile and desktop applications with both the client and their end users in mind. To us, this means a natural and intuitive interface, sensible, uncluttered layouts, tasteful graphics and typography, and ample support and documentation. It also means ensuring that an app looks great on all device sizes.
                            </p>
                        </Fade>

                        <div style={{ height: "24px" }}></div>

                        <Fade >
                            <p className="customTextTwo">
                                Whether you would like to add a mobile app to your existing business model, or just have a great idea that you want to bring to life, <span style={{ fontWeight: '900' }}>Moroz&nbsp;Digital</span> can help you achieve your vision.
                            </p>
                        </Fade>


                    </div>
                    <div className={textSpacerClassName}></div>
                </div>

                <div className="row" style={{ height: "64px" }}></div>


                <div className="row">
                    <div className="col-lg-2"></div>
                    <div className="col-lg-8">
                        <Fade >
                            <PhotoGallery photos={appDevelopmentPhotos} showCaptions={true} />
                        </Fade>
                    </div>
                    <div className="col-lg-2"></div>
                </div>

                <div className="row" style={{ height: "64px" }}></div>

                <div className="row">
                    <div className="col text-center">
                        <Fade >
                            <Button tag={Link} to="/app-development" className="justify-content-center" style={{ borderColor: "rgb(60, 153, 163)", backgroundColor: "rgb(60, 153, 163)" }}>Learn More About App Development</Button>
                        </Fade>
                    </div>
                </div>

                <div className="row" style={{ height: "128px" }}></div>

                <Footer />
                {/* </div> */}
            </div>
        );
    }
}

const mapSizesToProps = ({ width }) => ({
    collapseButtonIsShowing: width < 992,
    width: width
})

export default withSizes(mapSizesToProps)(HomePage);

// export default HomePage;
