import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";

import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'


const initialState = {};
const middleware = [thunk];

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

// const store = createStore(
//   persistedReducer,    
//   initialState,
//   applyMiddleware(...middleware)  
// );

// const store = createStore(
//   persistedReducer,
//   initialState,
//   compose(
//     applyMiddleware(...middleware),
//     (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
//       window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()) ||
//       compose
//   )
// );

export default () => {

  let store = createStore(
    persistedReducer,
    initialState,
    compose(
      applyMiddleware(...middleware),
      (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()) ||
        compose
    )
  );

  let persistor = persistStore(store)

  return { store, persistor }
}




// export default store;
