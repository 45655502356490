import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import { Provider } from "react-redux";
import returnStoreAndPersistor from "./store";

import { PersistGate } from 'redux-persist/integration/react'
// import SafeArea from 'react-safe-area-component'

import ScrollToTop from './ScrollToTop';

import HomePage from "./components/home/HomePage";
import AppDevelopmentPage from "./components/pages/AppDevelopmentPage";
import WebDesignPage from "./components/pages/WebDesignPage";
import ContactPage from './components/pages/ContactPage';
import AboutPage from './components/pages/AboutPage';
import FAQPage from './components/pages/FAQPage';
import NavigationBar from './components/layout/NavigationBar';

// import StorePage from './components/pages/StorePage';

import 'bootstrap/dist/css/bootstrap.css';
import "./App.css";
import "./BurgerMenu.css"
// import './Spinner.scss';


const { store } = returnStoreAndPersistor()
const { persistor } = returnStoreAndPersistor()

class App extends Component {

    componentWillMount() {
        // console.log('App.componentWillMount called.');    

        global.serverAddress = (process.env.NODE_ENV === 'production') ? 'https://warm-island-78376.herokuapp.com' : 'http://localhost:5000'
    }


    render() {
        // console.log('running App.render()');

        return (

            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <Router>
                        <ScrollToTop>

                            <div className="App">
                                <div className="container-fluid p-0">
                                    {/* <SafeArea top bottom left right> */}
                                    <NavigationBar />

                                    <Route exact path="/" component={HomePage} />
                                    <Route exact path="/app-development" component={AppDevelopmentPage} />
                                    <Route exact path="/web-design" component={WebDesignPage} />
                                    <Route exact path="/contact" component={ContactPage} />
                                    <Route exact path="/about" component={AboutPage} />
                                    <Route exact path="/faq" component={FAQPage} />                                    
                                </div>
                            </div>

                        </ScrollToTop>
                    </Router>
                </PersistGate>
            </Provider>
        );
    }
}

export default App;
