
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Button, Spinner } from 'reactstrap';
import { connect } from "react-redux";

import Helmet from 'react-helmet';
import { AvForm, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import ReCAPTCHA from 'react-google-recaptcha';

import { submitContactForm } from "../../actions/contactActions";
import Footer from "../layout/Footer";

const recaptchaRef = React.createRef();

class ContactPage extends Component {
    constructor() {
        super();

        this.renderForm = this.renderForm.bind(this)
        this.renderSuccess = this.renderSuccess.bind(this)
        this.renderFailure = this.renderFailure.bind(this)
        this.beforeSubmitValidation = this.beforeSubmitValidation.bind(this)

        this.state = {
            firstName: "",
            lastName: "",
            email: "",
            subject: "",
            message: "",
            errors: {},

            formSubmitted: 0, // 0 = not yet submitted, 1 = successfully submitted, 2 = error while submitting
            recaptchaFailed: false
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    beforeSubmitValidation = e => {
        e.persist()
    }

    onSubmit = e => {

        e.preventDefault();

        const recaptchaValue = recaptchaRef.current.getValue();

        if (!recaptchaValue.length) {
            this.setState({ recaptchaFailed: true })
            return
        }

        const url = global.serverAddress + '/api/contact';

        const submission = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            subject: this.state.subject,
            message: this.state.message
        };


        const that = this

        this.props.submitContactForm(submission, function (result) {
            console.log(result)

            var value = (result === true) ? 1 : 2

            that.setState({
                formSubmitted: value
            })

        })

    };

    renderForm() {
        console.log("ContactPage.render called; value of this.props.progress: " + this.props.progress)

        const spinnerColor = this.props.progress > 0 ? '#0275d8' : '#ffffff00'
        const display = this.props.progress > 0 ? 'block' : 'none'
        // const display = 'block'

        return (
            <>

                {/* <div style={{ width: '40px', height: '40px', margin: 'auto' }}>
                    <Spinner style={this.props.progress > 0 ? { color: '#0275d8' } : { color: '#ffffff00' }} />
                </div> */}

                <div className="row">

                    <div className="col-lg-2"></div>

                    <div className="col-lg-8">

                        <div style={{ height: "32px" }}></div>

                        <div className="row">
                            <div className="col-md-12">
                                {/* <p className="customText" style={{ fontSize: '35px', textAlign: 'center' }}> */}
                                <p className="customHeader" style={{ textAlign: "center" }}>
                                    Contact
                        </p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <p className="customTextTwo" style={{ textAlign: "center" }}>
                                    Want to talk more about your project? Drop us a line!
                        </p>
                            </div>
                        </div>

                        <div className="row" style={{ height: "64px" }}></div>

                        <div className="row">

                            <div className="col-lg-12">

                                <AvForm onValidSubmit={this.onSubmit} beforeSubmitValidation={this.beforeSubmitValidation} id="contactForm">

                                    <div className="form-row">

                                        <AvGroup className="form-group col-md-4" style={{ paddingBottom: '15px' }}>
                                            <label htmlFor="firstName"><p className="customTextTwo">First Name</p></label>

                                            <AvInput

                                                id="firstName"
                                                name="firstName"
                                                onChange={this.onChange}
                                                placeholder="First Name"
                                                required
                                                type="text"
                                                value={this.state.firstName}
                                                className="form-control"
                                            />
                                            <AvFeedback>First name is required.</AvFeedback>

                                        </AvGroup>

                                        <AvGroup className="form-group col-md-4" style={{ paddingBottom: '15px' }}>
                                            <label htmlFor="lastName"><p className="customTextTwo">Last Name</p></label>
                                            <AvInput
                                                id="lastName"
                                                name="lastName"
                                                onChange={this.onChange}
                                                placeholder="Last Name"
                                                required
                                                value={this.state.lastName}
                                                type="text"
                                                className="form-control"
                                            />
                                            <AvFeedback>Last name is required.</AvFeedback>
                                        </AvGroup>

                                        <AvGroup className="form-group col-md-4" style={{ paddingBottom: '15px' }}>
                                            <label htmlFor="email"><p className="customTextTwo">Email Address</p></label>
                                            <AvInput
                                                id="email"
                                                name="email"
                                                onChange={this.onChange}
                                                placeholder="first.last@example.com"
                                                required
                                                value={this.state.email}
                                                type="email"
                                                className="form-control"
                                            />
                                            <AvFeedback>A valid email is required.</AvFeedback>
                                        </AvGroup>

                                    </div>

                                    <AvGroup className="form-group" style={{ paddingBottom: '15px' }}>
                                        <label htmlFor="subject"><p className="customTextTwo">Subject</p></label>
                                        <AvInput
                                            id="subject"
                                            name="subject"
                                            onChange={this.onChange}
                                            placeholder="Subject"
                                            required
                                            value={this.state.subject}
                                            type="text"
                                            className="form-control"
                                        />
                                        <AvFeedback>Subject is required.</AvFeedback>
                                    </AvGroup>

                                    <AvGroup className="form-group" style={{ paddingBottom: '15px' }}>
                                        <label htmlFor="message"><p className="customTextTwo">Message</p></label>

                                        <AvInput
                                            id="message"
                                            name="message"
                                            onChange={this.onChange}
                                            placeholder="Message"
                                            required
                                            value={this.state.message}
                                            type="textarea"
                                            style={{ height: '30vh' }}
                                        />

                                        <AvFeedback>Message is required.</AvFeedback>
                                    </AvGroup>

                                    <AvGroup className="float-right">
                                        <ReCAPTCHA
                                            ref={recaptchaRef}
                                            sitekey="6LcvfscZAAAAABqIBnnq7QkBB3zd2huTnriGcLJQ"
                                        />
                                        {this.state.recaptchaFailed ? <label htmlFor="recaptchaFailed" className="float-right" style={{ color: '#CA444A', fontSize: '13px' }}>Please be a human.</label> : <></>}
                                    </AvGroup>

                                    {/* <Button form="contactForm" className="float-right" color="primary">Submit</Button>
                            {this.state.recaptchaFailed ? <label htmlFor="recaptchaFailed" style={{ color: '#CA444A', fontSize: '13px' }}>Please be a human.</label> : <div></div>} */}

                                </AvForm>

                            </div>

                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <Button form="contactForm" className="float-right" color="primary">Submit</Button>
                                {/* {this.state.recaptchaFailed ? <label htmlFor="recaptchaFailed" className="float-right" style={{ color: '#CA444A', fontSize: '13px' }}>Please be a human.</label> : <div></div>} */}

                                {/* <div className="spinner-border float-right" style={{ width: '38px', height: '38px', color: '#952143', marginRight: '16px' }} role="status">
                            <span class="sr-only">Loading...</span>
                        </div> */}

                                {/* <div className="loader-wrapper float-right" style={ this.props.progress > 0 ? { display: 'block' } : { display: 'block' }}> */}
                                <div className="loader-wrapper" style={{ display: display }}>
                                    <div className="loader-box float-right" style={{ marginTop: '1px', marginRight: '16px' }} >
                                        <div className="loader" >Loading...</div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div style={{ height: "64px" }}></div>

                    </div>


                    {/* <div className="row">
                    <div className="col-md-12">
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey="6LcHn7kZAAAAAInuIrAIFF1UqsS9Wc7GlekMD7kT"
                        />

                        <Button form="contactForm" className="float-right" color="primary">Submit</Button>
                        {this.state.recaptchaFailed ? <label htmlFor="recaptchaFailed" style={{ color: '#CA444A', fontSize: '13px' }}>Please be a human.</label> : <div></div>}

                    </div>
                </div> */}

                    <div className="col-lg-2"></div>

                </div>

                <Footer />
            </>

        );
    }

    renderSuccess() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p className="customTextTwo" style={{ fontSize: '20px', textAlign: 'center' }}>
                            Contact form submitted successfully.
                        </p>
                    </div>
                </div>
            </div>
        )
    }

    renderFailure() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p className="customTextTwo" style={{ fontSize: '20px', textAlign: 'center' }}>
                            Error submitting the contact form. Please <Link onClick={() => { window.location.reload(true) }}>try again.</Link>
                        </p>
                    </div>
                </div>
            </div>
        )
    }



    render() {
        const { errors } = this.state;

        switch (this.state.formSubmitted) {
            case 0: {
                return (
                    <div className="p-2">
                        {/* <Helmet bodyAttributes={{ style: 'background-color: rgba(75, 153, 201, 1)' }} /> */}
                        <Helmet bodyAttributes={{ style: "background-color: rgb(3, 60, 79)" }}>
                            <title>Contact – Moroz Digital</title>
                        </Helmet>
                        {this.renderForm()}
                    </div>
                )

            }
            case 1: {
                return (
                    <div className="p-2">
                        {/* <Helmet bodyAttributes={{ style: 'background-color: rgba(75, 153, 201, 1)' }} /> */}
                        <Helmet bodyAttributes={{ style: "background-color: rgb(3, 60, 79)" }}>
                            <title>Contact – Moroz Digital</title>
                        </Helmet>
                        {this.renderSuccess()}
                    </div>
                )
            }
            case 2: {
                return (
                    <div className="p-2">
                        {/* <Helmet bodyAttributes={{ style: 'background-color: rgba(75, 153, 201, 1)' }} /> */}
                        <Helmet bodyAttributes={{ style: "background-color: rgb(3, 60, 79)" }}>
                            <title>Contact – Moroz Digital</title>
                        </Helmet>
                        {this.renderFailure()}
                    </div>
                )
            }
        }


    }
}


const mapStateToProps = state => ({
    errors: state.errors,
    progress: state.progress
});

export default connect(
    mapStateToProps,
    { submitContactForm }
)(withRouter(ContactPage));

// export default connect(
//     mapStateToProps,
//     { submitContactForm }
// )(withRouter(ContactPage));

// export default ContactPage;